import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

import { noResults } from '../selectors';

export class NoExamSearchResults extends Component {
  render() {
    const { searchTerm, show, departmentCode, year } = this.props;
    const hasTerm = searchTerm.length > 0 || departmentCode.length > 0 || year.length > 0;

    const searchTermDisplay = searchTerm && (
      <span>- <b className="reflected-query">{searchTerm}</b> -</span>
    );

    const suggestionsYear = year && (
      <li>Try making your search more general by selecting &quot;any&quot;
        from the year dropdown</li>
    );

    return hasTerm && show && (
      <div className="no-results">
        <h3>No matching results</h3>

        <p>Your search {searchTermDisplay} did not match
        any documents.</p>

        <p>Suggestions:</p>

        <ul>
          {suggestionsYear}
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
        </ul>
      </div>
    );
  }
}

NoExamSearchResults.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  departmentCode: PropTypes.string,
  year: PropTypes.string,
  show: PropTypes.bool,
};

NoExamSearchResults.defaultProps = {
  show: false,
  departmentCode: '',
  year: '',
};

const mapStateToProps = state => ({
  searchTerm: state.search.searchTerm.trim(),
  departmentCode: state.search.scope.departmentCode,
  year: state.search.scope.year,
  show: noResults(state),
});

export default connect(mapStateToProps)(NoExamSearchResults);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import hash from 'object-hash';
import { chunk, findLastIndex, flatten, has, isEqual, reduce } from 'lodash';

import { WEBSERVICES, FETCH_STATES, DISPLAY_BATCH_SIZE, ENDPOINT_REGEX } from '../AppConstants';
import { fetchResults, saveClickthrough, storeCount, storeMoreResultsButtonClicked } from '../actions/search';
import MoreResultsButton from './MoreResultsButton';
import NoMoreResultsFader from './NoMoreResultsFader';

const partition = (array, length) =>
  (array.length ? [array.splice(0, length)].concat(partition(array, length)) : []);

const sliceResults = (results, sliceBestMatch) => {
  const rawSlices = partition(results.slice(), DISPLAY_BATCH_SIZE);

  const slices = sliceBestMatch ? Array(
    flatten(rawSlices).slice(0, 1),
  ).concat(
    chunk(
      flatten(rawSlices).slice(1), DISPLAY_BATCH_SIZE,
    ),
  ) : rawSlices;

  const sliceCounts = reduce(slices, (acc, s) => {
    let count = s.length;
    if (acc.length > 0) {
      count += acc[acc.length - 1];
    }
    acc.push(count);
    return acc;
  }, []);

  return {
    slices,
    sliceCounts,
  };
};

const isBestMatch = (serviceName, foundBestMatch) =>
  WEBSERVICES[serviceName].showBestResultOnly && foundBestMatch;

const updateQueryCount = (countPrefix, nextCount, context) => {
  const { location } = context;

  const newLocation = {
    pathname: location.pathname,
    query: location.query,
  };

  if (countPrefix && nextCount) {
    newLocation.query[countPrefix] = nextCount;
  }
  return newLocation;
};

export class SearchResults extends Component {
  constructor(props, context) {
    super(props, context);

    const service = WEBSERVICES[props.serviceName];
    this.countPrefix =
      has(service, 'arguments') && has(service.arguments, 'prefix') && service.arguments.prefix ?
        `${service.arguments.prefix}c` : null;

    const sliceData = sliceResults(
      props.results,
      isBestMatch(props.serviceName, props.foundBestMatch),
    );

    this.state = {
      ...sliceData,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.resultsToShow, this.props.resultsToShow)) {
      // fetch next batch
      const {
        resultsToShow,
        isComplete,
        searchTerm,
        scope,
        dispatch,
        serviceName,
      } = nextProps;

      if (!isComplete) {
        const query = {
          q: searchTerm,
          debug: this.context.location.query.debug,
          ...scope,
        };

        const offset = resultsToShow - DISPLAY_BATCH_SIZE;

        const endpoint = (has(nextProps, 'endpoint') &&
          nextProps.endpoint.match(new RegExp(ENDPOINT_REGEX))) ?
          nextProps.endpoint : null;

        dispatch(fetchResults(serviceName, query, offset, DISPLAY_BATCH_SIZE, endpoint));
      }
    }

    if (!isEqual(nextProps.results, this.props.results)) {
      // rebuild slices
      this.setState(
        sliceResults(
          nextProps.results,
          isBestMatch(nextProps.serviceName, nextProps.foundBestMatch),
        ),
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (!isEqual(nextState, this.state) || !isEqual(nextProps, this.props));
  }

  render() {
    let renderedResults;
    let paginationComponent;

    const {
      resultsToShow,
      retrievedCount,
      isComplete,
      isMoreResultsButtonClicked,
      footer,
      searchTerm,
      queryId,
      scope,
      fetchState,
      onRetryClick,
      onResultClick,
      serviceName,
      serviceRank,
      ResultComponent,
      dispatch,
    } = this.props;

    const service = WEBSERVICES[serviceName];
    const { title, description } = service;
    const { slices, sliceCounts } = this.state;

    if (fetchState === FETCH_STATES.FAILED) {
      const query = {
        q: searchTerm,
        ...scope,
      };

      const offset = retrievedCount;
      const retry = () => onRetryClick(serviceName, query, offset, resultsToShow - retrievedCount);

      /* eslint-disable jsx-a11y/no-static-element-interactions */
      renderedResults = (
        <div className="fetch-error">
          <h6>Sorry, something went wrong.</h6>
          <p>There was a problem looking up data from this source.
          We&rsquo;ve logged it to try to fix it.</p>
          <div>
            <a
              role="button"
              tabIndex={0}
              className="btn btn-sm btn-primary"
              onClick={retry}
            >
              Try again
            </a>
          </div>
        </div>
      );
      /* eslint-enable jsx-a11y/no-static-element-interactions */
    } else if (slices.length) {
      const slicesToShow = 1 + (findLastIndex(sliceCounts, c => (c <= resultsToShow)) || 0);
      const renderedSlices = flatten(slices.slice(0, slicesToShow));

      if (this.countPrefix) {
        if (!isComplete) {
          const label = `More ${description}`;
          const nextCount = resultsToShow + DISPLAY_BATCH_SIZE;
          const more = () => {
            this.props.router.push(
              updateQueryCount(this.countPrefix, nextCount, this.context),
            );
            dispatch(storeCount(serviceName, nextCount));
            dispatch(storeMoreResultsButtonClicked(serviceName));
          };

          paginationComponent = (
            <MoreResultsButton
              isFetching={fetchState === FETCH_STATES.FETCHING}
              onClick={more}
              label={label}
            />
          );
        } else if (isMoreResultsButtonClicked) { // implicitly && isComplete
          paginationComponent = (
            <NoMoreResultsFader />
          );
        }
      }

      renderedResults = renderedSlices.map(
        (result, resultRank) => {
          const key = hash(result);

          return (
            <ResultComponent
              result={result}
              resultRank={resultRank}
              key={key}
              queryId={queryId}
              scope={scope}
              onClick={onResultClick}
              serviceName={serviceName}
              serviceRank={serviceRank}
            />
          );
        },
      );

      renderedResults = (
        <ul className="results list-unstyled">
          {renderedResults}
        </ul>
      );
    }

    if (renderedResults) {
      const renderedFooter = footer && (
        // eslint-disable-next-line react/no-danger
        <div className="results-footer" dangerouslySetInnerHTML={{ __html: footer }} />
      );

      return (
        <div className="search-results">
          <h3>{title}</h3>
          <span className="hide">{retrievedCount}</span>
          {renderedResults}
          {paginationComponent}
          {renderedFooter}
        </div>
      );
    }

    return false;
  }
}

SearchResults.propTypes = {
  serviceName: PropTypes.string.isRequired,
  serviceRank: PropTypes.number.isRequired,
  footer: PropTypes.string,
  ResultComponent: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  queryId: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  scope: PropTypes.object.isRequired,
  retrievedCount: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isMoreResultsButtonClicked: PropTypes.bool,
  resultsToShow: PropTypes.number.isRequired,
  fetchState: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  foundBestMatch: PropTypes.bool,
  onRetryClick: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func.isRequired,
};

SearchResults.defaultProps = {
  footer: null,
  foundBestMatch: false,
  isMoreResultsButtonClicked: false,
};

SearchResults.contextTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const service = state.search.services[ownProps.serviceName];
  return {
    user: state.sso.user,
    searchTerm: state.search.searchTerm,
    scope: state.search.scope,
    queryId: state.search.queryId,
    endpoint: service.endpoint,
    fetchState: service.state,
    retrievedCount: service.results.length,
    isComplete: service.isComplete,
    isMoreResultsButtonClicked: service.isMoreResultsButtonClicked,
    resultsToShow: service.resultsToShow || DISPLAY_BATCH_SIZE,
  };
};

const mapDispatchToProps = dispatch => ({
  onRetryClick: (sn, query, offset, count) =>
    dispatch(fetchResults(sn, query, offset, count)),
  onResultClick: (sn, searchContext) =>
    dispatch(saveClickthrough(sn, searchContext)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResults));

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SearchResult from './SearchResult';

export const WebSearchResult = ({ result, ...other }) => {
  const metadata = result.lastModified ?
    moment(result.lastModified).format('Do MMM YYYY') : '';

  let title = result.title;
  if (result.siteDescription && !title.includes(result.siteDescription)) {
    title = `${result.siteDescription} - ${title}`;
  }

  // Use SBTWO-specific rendering for image thumbnails
  const thumbnail = /\.(jpe?g|gif|png)$/.test(result.link) ? {
    src: `${result.link}?maxWidth=150&maxHeight=150`,
    alt: `Thumbnail for ${result.title}`,
  } : {};

  const summary = (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: result.matchText }} />
  );

  const { explanation } = result;

  return (
    <SearchResult
      {...other}
      thumbnail={thumbnail}
      link={result.link}
      title={title}
      summary={summary}
      metadata={metadata}
      explanation={explanation}
    />
  );
};

export default WebSearchResult;

WebSearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

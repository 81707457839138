import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchResults from './SearchResults';
import GoSearchResult from './GoSearchResult';

const foundExactPathMatch = (searchTerm, results) => (
  results.length ? results[0].path === searchTerm : false
);

export const GoSearchResults = ({ ...props }) => {
  const { searchTerm, results } = props;
  if (results.length) {
    return (
      <SearchResults
        {...props}
        ResultComponent={GoSearchResult}
        foundBestMatch={foundExactPathMatch(searchTerm, results)}
      />
    );
  }

  return false;
};


GoSearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  results: state.search.services.goSearch.results,
  searchTerm: state.search.searchTerm.toLowerCase(),
});

export default connect(mapStateToProps)(GoSearchResults);

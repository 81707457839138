import { USER_RECEIVE, SSO_LINKS_RECEIVE, CSRF_TOKEN_RECEIVE } from '../AppConstants';

export default function sso(state = {
  user: {
    authoritative: false,
    authenticated: false,
  },
  links: {
    login: null,
    logout: null,
    popover: null,
  },
  csrfToken: null,
}, action) {
  switch (action.type) {
    case USER_RECEIVE:
      return {
        ...state,
        user: action.user,
      };

    case SSO_LINKS_RECEIVE:
      return {
        ...state,
        links: action.links,
      };

    case CSRF_TOKEN_RECEIVE:
      return {
        ...state,
        csrfToken: action.token,
      };

    default:
      return state;
  }
}

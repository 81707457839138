import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ZERO_WIDTH_SPACE = String.fromCharCode(8203);

export default class CuratedLink extends Component {
  onDelete(id) {
    return () => this.props.onDelete(id);
  }

  onEdit(id) {
    return () => this.props.onEdit(id);
  }

  render() {
    const { result } = this.props;

    if (!result) {
      return false;
    }

    const splittableUrl = result.url.replace(/\//g, `/${ZERO_WIDTH_SPACE}`);

    return (
      <div className="row">
        <div className="col-sm-4">{result.title}</div>
        <div className="col-sm-4"><a href={result.url}>{splittableUrl}</a></div>
        <div className="col-sm-1">{result.weighting}</div>
        <div className="col-sm-3">
          <button type="button" className="btn btn-default" onClick={this.onEdit(result.id)}>
            Edit
          </button>
          <button type="button" className="btn btn-danger" onClick={this.onDelete(result.id)}>
            Delete
          </button>
        </div>
      </div>
    );
  }
}

CuratedLink.propTypes = {
  result: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

CuratedLink.defaultProps = {
  result: null,
};

import 'whatwg-fetch';
import querystring from 'querystring';
import { assign, find, forOwn, get, has, includes, isEmpty, isFinite, isUndefined, some, union } from 'lodash';
import { validLengthTerm } from '../utils';

import {
  TimeoutError,
  timeout,
  getWithCredentials,
  postJsonWithCredentials,
  saveError,
  saveFetchTimeout,
} from './FetchHelpers';

import {
  STORE_SEARCH_TERM,
  STORE_SEARCH_SCOPE,
  STORE_COUNT,
  MORE_BUTTON_CLICKED,
  QUERY_ID_RECEIVE,
  RESULTS_REQUEST,
  RESULTS_RECEIVE,
  RESULTS_CLEAR,
  ROUTES,
  WEBSERVICES,
  BATCH_TYPES,
  SCOPE_ARGUMENTS,
  DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE,
} from '../AppConstants';

// pure action creators
export function storeSearchTerm(searchTerm) {
  return {
    type: STORE_SEARCH_TERM,
    searchTerm,
  };
}

export function storeQueryId(id) {
  return {
    type: QUERY_ID_RECEIVE,
    id,
  };
}

export function storeScope(scope) {
  return {
    type: STORE_SEARCH_SCOPE,
    scope,
  };
}

export function storeCount(service, resultsToShow) {
  return {
    type: STORE_COUNT,
    service,
    resultsToShow,
  };
}

export function storeMoreResultsButtonClicked(service) {
  return {
    type: MORE_BUTTON_CLICKED,
    service,
  };
}

export function requestResults(service, endpoint, query) {
  return {
    type: RESULTS_REQUEST,
    service,
    endpoint,
    query,
  };
}

export function receiveResults(service, json) {
  if (!json) {
    return {
      type: RESULTS_RECEIVE,
      searchTerm: '',
      results: [],
      batchType: BATCH_TYPES.FAILED_SEARCH,
      offset: 0,
      service,
    };
  }

  const { results, searchTerm = '', batchType = BATCH_TYPES.UNBATCHED, offset = 0 } = json;

  return {
    type: RESULTS_RECEIVE,
    searchTerm,
    results,
    batchType,
    offset,
    service,
  };
}

export function clearResults(service) {
  return {
    type: RESULTS_CLEAR,
    service,
  };
}

export function departmentsWithExamPapersReceive(json) {
  return {
    type: DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE,
    results: json,
  };
}

// helpers
function pickInsensitive(obj, propertyPaths) {
  // case-insensitive pick (returns keys with case as defined in propertyPaths)
  const result = {};
  propertyPaths.forEach((propertyPath) => {
    const found = find(obj, (_, k) => (k.toLowerCase() === propertyPath.toLowerCase()));
    if (!isUndefined(found)) {
      result[propertyPath] = found;
    }
  });

  return result;
}

function acceptArguments({ service, query = {} }) {
  const metadata = WEBSERVICES[service];
  const accept = get(metadata, 'arguments.accept', []);
  const acceptPrefixed = get(metadata, 'arguments.acceptPrefixed', []).map(
    arg => `${metadata.arguments.prefix}${arg}`,
  );

  const acceptedArguments = union(accept, acceptPrefixed);
  return pickInsensitive(query, acceptedArguments);
}

function isDeniedByArguments(service, query) {
  const metadata = WEBSERVICES[service];
  const deny = get(metadata, 'arguments.deny', []).map(a => a.toLowerCase());
  return deny.length && some(query, (value, key) => (includes(deny, key.toLowerCase())));
}

// thunks
export function saveOrRetrieveQuery(searchTerm, scope) {
  return (dispatch, getState) => {
    dispatch(storeSearchTerm(searchTerm));
    dispatch(storeScope(scope || {}));

    const sso = getState().sso || null;

    if (!sso) {
      return Promise.reject('No SSO state available');
    }

    const { csrfToken, user } = sso;
    const usercode = user && user.usercode ? user.usercode : null;

    if (csrfToken) {
      // we're making a fresh query, so save it
      return timeout(postJsonWithCredentials(
        ROUTES.searchHistory,
        csrfToken,
        {
          term: searchTerm,
          scope,
        },
      ))
        .then(response => response.json())
        .then(
          (json) => {
            if (json.saved && json.id) {
              dispatch(storeQueryId(json.id));
            } else {
              dispatch(saveError(new Error('Failed save'), 'Saving query'));
            }
          },
        )
        .catch((error) => {
          if (error instanceof TimeoutError) {
            dispatch(saveFetchTimeout(ROUTES.searchHistory));
          } else {
            dispatch(saveError(error, 'Saving query'));
          }
        });
    } else if (usercode) {
      // we're signed in, but the page is still loading, so retrieve last match
      let qs = `u=${usercode}&q=${encodeURIComponent(searchTerm)}`;
      qs += isEmpty(scope) ? '' : `&${querystring.stringify(scope)}`;
      const url = `${ROUTES.searchHistory}?${qs}`;
      return timeout(getWithCredentials(url))
        .then(
          response => (response.ok ? response.text() : Promise.resolve('')),
          () => dispatch(saveFetchTimeout(url)),
        )
        .then(
          (id) => {
            if (id.length) {
              dispatch(storeQueryId(id));
            }
          },
        )
        .catch(error => dispatch(saveError(error, 'Retrieving query ID')));
    }

    return Promise.resolve('No credentials available to retrieve query ID');
  };
}

export function saveClickthrough(service, searchContext) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;

    if (csrfToken) {
      return timeout(postJsonWithCredentials(
        ROUTES.clickHistory,
        csrfToken,
        {
          service,
          title: searchContext.title,
          queryId: searchContext.queryId,
          url: searchContext.url,
          scope: {
            urlPrefix: searchContext.urlPrefix || '',
            dateRange: searchContext.dateRange || '',
          },
          rank: {
            resultRank: searchContext.resultRank,
            serviceRank: searchContext.serviceRank,
          },
        },
      ))
        .then(response => response.json())
        .then(
          (json) => {
            if (!json.saved) {
              dispatch(saveError(new Error('Failed save'), 'Saving clickthrough'));
            }
          },
        )
        .catch((error) => {
          if (error instanceof TimeoutError) {
            dispatch(saveFetchTimeout(ROUTES.clickHistory));
          } else {
            dispatch(saveError(error, 'Saving clickthrough'));
          }
        });
    }

    return Promise.resolve('No viable credentials for saving');
  };
}

export function fetchResults(service, query, offset, count, specificEndpoint) {
  return (dispatch) => {
    const batchArguments = {};
    if (offset) {
      assign(batchArguments, { offset });
    }
    if (count) {
      assign(batchArguments, { count });
    }
    const acceptedArguments = assign({}, acceptArguments({ service, query }), batchArguments);
    const endpoint = specificEndpoint || ROUTES[service];

    dispatch(requestResults(service, endpoint, acceptedArguments));
    const url = `${endpoint}?${querystring.stringify(acceptedArguments)}`;

    return timeout(getWithCredentials(url))
      .then(
        (response) => {
          if (response.status === 204) {
            return { results: [] };
          }

          return response.json();
        },
      )
      .then((json) => {
        dispatch(receiveResults(service, json));
        return Promise.resolve();
      })
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(url));
        } else {
          dispatch(saveError(error, `Parsing JSON from ${service}`));
        }
        dispatch(receiveResults(service));
        return Promise.resolve();
      });
  };
}

export function runQuery(query) {
  return (dispatch) => {
    const searchTerm = query.q;

    if (searchTerm && validLengthTerm(searchTerm)) {
      dispatch(saveOrRetrieveQuery(searchTerm, pickInsensitive(query, SCOPE_ARGUMENTS)));

      forOwn(WEBSERVICES, (metadata, service) => {
        if (isDeniedByArguments(service, query)) {
          dispatch(clearResults(service));
        } else {
          let count;
          if (has(metadata, 'arguments') && has(metadata.arguments, 'prefix')) {
            const countPrefix = `${metadata.arguments.prefix}c`;
            count = parseInt(query[countPrefix], 10);
          }
          if (isFinite(count)) {
            dispatch(storeCount(service, count));
            dispatch(fetchResults(service, query, 0, count));
          } else {
            dispatch(fetchResults(service, query));
          }
        }
      });
    } else {
      forOwn(WEBSERVICES, (value, service) => dispatch(clearResults(service)));
      dispatch(storeSearchTerm(''));
      dispatch(storeScope({}));
      dispatch(fetchResults('suggestedLinks'));
    }

    return Promise.resolve();
  };
}

function validQuery(query) {
  return (query.q && validLengthTerm(query.q))
    || (query.departmentCode && validLengthTerm(query.departmentCode))
    || (query.year && validLengthTerm(query.year));
}

export function runEndpointQuery(query, endpoint) {
  return (dispatch) => {
    const searchTerm = query.q;

    if (validQuery(query)) {
      const endpointSplit = endpoint.split(':');
      const endpointURL = endpointSplit[0];
      const service = endpointSplit[1] || 'webSearch';
      const metadata = WEBSERVICES[service];

      dispatch(saveOrRetrieveQuery(searchTerm, pickInsensitive(query, SCOPE_ARGUMENTS)));

      // clear all
      forOwn(WEBSERVICES, (aMetadata, aService) => {
        if (aService !== service) {
          dispatch(clearResults(aService));
        }
      });

      if (!isDeniedByArguments(service, query)) {
        let count;
        if (has(metadata, 'arguments') && has(metadata.arguments, 'prefix')) {
          const countPrefix = `${metadata.arguments.prefix}c`;
          count = parseInt(query[countPrefix], 10);
        }
        if (isFinite(count)) {
          dispatch(storeCount(service, count));
          dispatch(fetchResults(service, query, 0, count, endpointURL));
        } else {
          dispatch(fetchResults(service, query, null, null, endpointURL));
        }
      }
    } else {
      forOwn(WEBSERVICES, (value, service) => dispatch(clearResults(service)));
      dispatch(storeSearchTerm(''));
      dispatch(storeScope({}));
      dispatch(fetchResults('suggestedLinks'));
    }

    return Promise.resolve();
  };
}

export function fetchDepartmentsWithExamPapers() {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    if (csrfToken) {
      return timeout(getWithCredentials(
        ROUTES.departmentsWithExamPapers,
        csrfToken,
      ))
      .then((response) => {
        if (response.status === 204) {
          return { results: [] };
        }

        return response.json();
      },
      ).then((json) => {
        dispatch(departmentsWithExamPapersReceive(json));
        return Promise.resolve();
      })
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(ROUTES.departmentsWithExamPapers));
        } else {
          dispatch(saveError(error, 'Parsing departments list JSON for exam paper search'));
        }
        return Promise.resolve();
      });
    }

    return Promise.resolve();
  };
}

import React from 'react';
import { Link } from 'react-router';
import DocumentTitle from '../DocumentTitle';
import HomeLink from '../HomeLink';

export const Admin = () => {
  const documentTitle = 'Admin - Warwick Search';

  return (
    <div>
      <DocumentTitle title={documentTitle} />

      <h1>
        <img src="/assets/images/admin.jpg" alt="" />
        <HomeLink to="/admin">Administration</HomeLink>
      </h1>

      <ul>
        <li><Link to="/admin/curated-links">Default curated links</Link></li>
        <li><Link to="/admin/masquerade">Masquerade</Link></li>
      </ul>
    </div>
  );
};

export default Admin;

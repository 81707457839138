import React, { PropTypes, Component } from 'react';

export class SearchResultExplanation extends Component {
  componentDidMount() {
    jQuery(this.popoverLink).popover({
      container: 'div.search-results',
    });
  }

  render() {
    const { explanation } = this.props;
    const explanationFormatted = JSON.stringify(explanation, null, 2);
    const explanationDisplay = `<pre>${explanationFormatted}</pre>`;

    return (
      <div className="explanation">
        <a
          data-toggle="popover"
          data-html="true"
          data-placement="right"
          data-content={explanationDisplay}
          className="resultExplanation"
          ref={(c) => { this.popoverLink = c; }}
        >
          <i className="fa fa-question-circle" />
        </a>
      </div>
    );
  }
}

export default SearchResultExplanation;

SearchResultExplanation.propTypes = {
  explanation: PropTypes.object,
};

SearchResultExplanation.defaultProps = {
  explanation: {},
};

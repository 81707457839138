import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isVirgin } from '../selectors';

export class WelcomeHint extends Component {
  render() {
    const { show } = this.props;

    return show && (
      <p className="welcome-hint">
        <i className="far fa-fw fa-lg fa-hand-point-up" />
        Find web pages, people, campus map locations, past exam papers,
        research papers and more...</p>
    );
  }
}

WelcomeHint.propTypes = {
  show: PropTypes.bool,
};

WelcomeHint.defaultProps = {
  show: false,
};

const mapStateToProps = state => ({
  show: isVirgin(state),
});

export default connect(mapStateToProps)(WelcomeHint);

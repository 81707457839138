import {
  ADD_CURATED_LINK_SUCCESS,
  UPDATE_CURATED_LINK_SUCCESS,
  DELETE_CURATED_LINK_SUCCESS,
  CURATED_LINK_RECEIVE,
  CURATED_LINK_REQUEST,
  LOAD_CURATED_LINK_EDIT_FORM,
  CANCEL_CURATED_LINK_EDIT_FORM,
  FETCH_STATES,
} from '../AppConstants';

const initialState = {
  results: [],
  fetchState: '',
};

export default function curatedLink(state = initialState, action) {
  switch (action.type) {
    case ADD_CURATED_LINK_SUCCESS: {
      let newState;
      if (action.data) {
        const newResults = state.results.filter(val => val.title !== action.data.title);
        newResults.push(action.data);
        newResults.sort((a, b) => b.weighting - a.weighting);
        newState = {
          ...state,
          fetchState: FETCH_STATES.IDLE,
          results: newResults,
        };
      } else {
        newState = {
          ...state,
          fetchState: FETCH_STATES.FAILED,
        };
      }

      return newState;
    }

    case UPDATE_CURATED_LINK_SUCCESS: {
      let newState;
      if (action.data) {
        const newResults = state.results.filter(val => val.id !== action.data.id);
        newResults.push(action.data);
        newResults.sort((a, b) => b.weighting - a.weighting);
        newState = {
          ...state,
          fetchState: FETCH_STATES.IDLE,
          results: newResults,
          editing: null,
        };
      } else {
        newState = {
          ...state,
          fetchState: FETCH_STATES.FAILED,
        };
      }

      return newState;
    }

    case DELETE_CURATED_LINK_SUCCESS: {
      let newState;
      if (action.id) {
        const newResults = state.results.filter(val => val.id !== action.id);
        newState = {
          ...state,
          fetchState: FETCH_STATES.IDLE,
          results: newResults,
        };
      } else {
        newState = {
          ...state,
          fetchState: FETCH_STATES.FAILED,
        };
      }

      return newState;
    }

    case CURATED_LINK_REQUEST: {
      return {
        ...state,
        fetchState: FETCH_STATES.FETCHING,
        results: [],
      };
    }

    case CURATED_LINK_RECEIVE: {
      const responseData = (action.results) ?
      { fetchState: FETCH_STATES.IDLE, results: action.results } :
      { fetchState: FETCH_STATES.FAILED, results: [] };

      return {
        ...state,
        ...responseData,
      };
    }

    case LOAD_CURATED_LINK_EDIT_FORM: {
      return {
        ...state,
        editing: action.id,
      };
    }

    case CANCEL_CURATED_LINK_EDIT_FORM: {
      return {
        ...state,
        editing: null,
      };
    }

    default:
      return state;
  }
}

export function resetForm(state, action) {
  switch (action.type) {
    case ADD_CURATED_LINK_SUCCESS:
      return undefined;
    default:
      return state;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NoLayout extends Component {
  getChildContext() {
    return {
      isEmbedded: true,
      location: this.props.location,
    };
  }

  render() {
    const { children } = this.props;

    return (
      <main className="no-layout">
        {children}
      </main>
    );
  }
}

NoLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
};

NoLayout.childContextTypes = {
  isEmbedded: PropTypes.bool,
  location: PropTypes.object,
};

NoLayout.defaultProps = {
  children: null,
};

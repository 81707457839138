import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const MasqueradeNotice = ({ user }) => {
  if (user.authenticated && user.isMasquerading) {
    return (
      <div className="masquerade-notice">
        Masquerading as <strong>{user.name}
        </strong> ({user.usercode}). <a href="/admin/masquerade">Change</a>
      </div>
    );
  }
  return null;
};

/* eslint-disable react/require-default-props */
MasqueradeNotice.propTypes = {
  user: PropTypes.object.isRequired,
};

/* eslint-enable react/require-default-props */

const mapStateToProps = state => ({
  user: state.sso.user,
});

export default connect(mapStateToProps)(MasqueradeNotice);

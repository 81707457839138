import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from './SearchResult';

export const PeopleSearchResult = ({ result, ...other }) => {
  const {
    membershipDetails,
    jobDescription,
    department,
    extensionNumber,
    sequenceNumber,
    targetGroup,
  } = result;

  const {
    title,
    preferredFirstName,
    preferredSurname,
    email,
    universityId,
  } = membershipDetails;

  const resultTitle = `${title || ''} ${preferredFirstName} ${preferredSurname}`.trim();

  const deptSpan = department && (
    <span className="dept">{department.name}</span>
  );

  let emailSpan;
  if (email) {
    const mailto = `mailto:${email}`;
    emailSpan = (
      <span className="email">
        <i className="far fa-fw fa-envelope" />&#8239;<a href={mailto}>{email}</a>
      </span>
    );
  }

  const extensionNumberSpan = extensionNumber && (
    <span className="phone"><i className="far fa-fw fa-phone" />&#8239;{extensionNumber}</span>
  );

  const contactSpan = (email || extensionNumber) && (
    <span className="contact">
      {emailSpan}
      {extensionNumberSpan}
    </span>
  );

  const summaryDiv = (
    <div className="summary">
      <span className="role">{jobDescription || targetGroup}</span>
      {deptSpan}
      {contactSpan}
    </div>
  );

  let link;
  if (universityId && sequenceNumber != null) {
    link = `https://peoplesearch.warwick.ac.uk/profile/${universityId}/${sequenceNumber}`;
  }

  return (
    <SearchResult {...other} link={link} title={resultTitle} summary={summaryDiv} />
  );
};

export default PeopleSearchResult;

PeopleSearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

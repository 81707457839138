import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NavLink from './NavLink';

export const ID7Navigation = ({ user, router, location }) => {
  const roles = user.roles || [];

  if (!roles.includes('admin')) {
    return false;
  }

  const isRoot = router.isActive({ pathname: '/' }, true);
  const showTertiary = router.isActive({ pathname: '/admin' });

  const tertiaryUrls = [{
    url: '/admin/curated-links',
    name: 'Curated links',
  }, {
    url: '/admin/masquerade',
    name: 'Masquerade',
  }];


  const tertiaryLinks = active => tertiaryUrls.map((urlAndName) => {
    const url = urlAndName.url;
    const name = urlAndName.name;

    return (
      <NavLink
        router={router}
        to={url}
        activeLiClassName={active === url ? 'active' : ''}
      >
        {name}
      </NavLink>
    );
  });

  const primaryClass = `navbar navbar-primary${isRoot ? '' : ' hidden-xs'}`;
  const primaryNav = (
    <nav className={primaryClass}>
      <ul className="nav navbar-nav">
        <NavLink router={router} liClassName="dropdown" to="/admin">
          Admin
          <ul className="dropdown-menu" role="menu">
            {tertiaryLinks(location.pathname)}
          </ul>
        </NavLink>
      </ul>
    </nav>
  );

  const tertiaryNav = showTertiary && (
    <nav className="navbar navbar-tertiary">
      <ul className="nav navbar-nav">
        {tertiaryLinks(location.pathname)}
      </ul>
    </nav>
  );

  return (
    <div>
      {primaryNav}
      {tertiaryNav}
    </div>
  );
};

ID7Navigation.propTypes = {
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.sso.user,
  location: state.routing.locationBeforeTransitions,
});

export default connect(mapStateToProps)(withRouter(ID7Navigation));

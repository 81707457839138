import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SearchResult from './SearchResult';

moment.updateLocale('en', {
  calendar: {
    lastDay: '[yesterday at] h.mma',
    sameDay: '[today at] h.mma',
    nextDay: '[tomorrow at] h.mma',
    lastWeek: '[last] dddd [at] h.mma',
    nextWeek: 'dddd [at] h.mma',
    sameElse: 'Do MMMM YYYY',
  },
});

export const SuggestedLink = ({ result, ...other }) => {
  const { data, source } = result;
  const { url, title, id } = data;
  let metadata;

  if (source === 'CuratedLink') {
    metadata = 'We thought this might be useful to you';
  } else if (source === 'PersonalisedLink') {
    metadata = data.lastVisit ? `Visited ${moment(data.lastVisit).calendar()}` :
      'You\'ve visited this link before';
    metadata += data.visits ? `, ${data.visits} visits from Warwick Search` : '';
    metadata = metadata.replace('1 visits', '1 visit');
  }

  return (
    <div className="col-sm-6 suggestions-card">
      <SearchResult
        {...other}
        queryId={id}
        link={url}
        title={title}
        metadata={metadata}
        hideLink
      />
    </div>
  );
};

export default SuggestedLink;

SuggestedLink.propTypes = {
  result: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

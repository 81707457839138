import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as user from '../actions/user';

export class Masquerade extends Component {
  constructor(props) {
    super(props);

    const isMasquerading = props.user.isMasquerading;
    const masqueradingAs = isMasquerading ? `${props.user.name} (${props.user.usercode})` : '';

    this.state = {
      masqueradeAs: '',
      masqueradingAs,
      isMasquerading,
    };

    this.handleChange = this.handleChange.bind(this);
    this.masquerade = this.masquerade.bind(this);
    this.stopMasquerading = this.stopMasquerading.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const isMasquerading = nextProps.user.isMasquerading;
    const masqueradingAs = isMasquerading ? `${nextProps.user.name} (${nextProps.user.usercode})` : '';

    this.setState({
      ...this.state,
      masqueradingAs,
      isMasquerading,
    });
  }

  handleChange(event) {
    this.setState({ masqueradeAs: event.target.value });
  }

  masquerade(e) {
    e.preventDefault();

    this.props.dispatch(user.masquerade(this.state.masqueradeAs));
  }

  stopMasquerading(e) {
    e.preventDefault();

    this.props.dispatch(user.stopMasquerading());
  }

  render() {
    return (
      <div>
        {this.state.isMasquerading &&
          <div className="form-group">
            <p>You are currently masquerading as {this.state.masqueradingAs}</p>
            <button className="btn btn-danger" onClick={this.stopMasquerading}>Stop masquerading</button>
          </div>
        }
        <div className="form-group">
          <p>Masquerading allows you to see the site exactly as another user would see it.</p>
          <div className="input-group">
            <input type="text" className="form-control" name="usercode" placeholder="Type a usercode" value={this.state.masqueradeAs} onChange={this.handleChange} />
            <div className="input-group-btn">
              <button className="btn btn-default" type="submit" onClick={this.masquerade}>
                <i className="fas fa-fw fa-user-secret" />
                Mask
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Masquerade.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.sso.user,
});

export default connect(mapStateToProps)(Masquerade);

import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from './SearchResult';

export const GoSearchResult = ({ result, ...other }) => {
  const { description, path } = result;
  const link = `http://warwick.ac.uk/${path}`;

  return (
    <SearchResult {...other} link={link} title={description} />
  );
};

export default GoSearchResult;

GoSearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

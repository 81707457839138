/* global fetch */
import 'whatwg-fetch';

import {
  LOG_ERROR,
  LOG_TIMEOUT,

  API_TIMEOUT_PERIOD,

  ROUTES,
} from '../AppConstants';

// pure action creators
export function logError(error, context) {
  return {
    type: LOG_ERROR,
    context,
    name: error.name,
    message: error.message,
  };
}

export function logFetchTimeout(url) {
  return {
    type: LOG_TIMEOUT,
    timeoutUrl: url,
  };
}


// helpers
export function TimeoutError(message) {
  this.name = 'TimeoutError';
  this.message = message || 'timeout';
  this.stack = (new Error()).stack;
}
TimeoutError.prototype = Object.create(Error.prototype);
TimeoutError.prototype.constructor = TimeoutError;

export function timeout(promise, duration) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new TimeoutError());
    }, duration || API_TIMEOUT_PERIOD);
    promise.then(resolve, reject);
  });
}

export function getWithCredentials(url, opts) {
  return fetch(url, {
    ...opts,
    credentials: 'same-origin',
  });
}

export function postWithCredentials(url, csrfToken, body, opts) {
  return fetch(url, {
    ...opts,
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Csrf-Token': csrfToken,
    },
    body,
  });
}

export function deleteWithCredentials(url, csrfToken, opts) {
  return fetch(url, {
    ...opts,
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Csrf-Token': csrfToken,
    },
  });
}

export function putJsonWithCredentials(url, csrfToken, json, opts) {
  return fetch(url, {
    ...opts,
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Csrf-Token': csrfToken,
    },
    body: JSON.stringify(json),
  });
}

export function postJsonWithCredentials(url, csrfToken, json, opts) {
  return fetch(url, {
    ...opts,
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Csrf-Token': csrfToken,
    },
    body: JSON.stringify(json),
  });
}

export function saveError(error, context) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    dispatch(logError(error, context));
    // allow logging fetch to fail silently; if it's this broken, we're doomed
    postJsonWithCredentials(
      ROUTES.saveError,
      csrfToken,
      {
        error: error.message,
        context,
      },
    );
  };
}

export function saveFetchTimeout(url) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    dispatch(logFetchTimeout(url));
    // allow logging fetch to fail silently; if it's this broken, we're doomed
    postJsonWithCredentials(
      ROUTES.saveTimeout,
      csrfToken,
      {
        url,
      },
    );
  };
}

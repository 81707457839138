import * as urlModule from 'url';
import React from 'react';
import PropTypes from 'prop-types';
import RemoteImage from './RemoteImage';
import { THUMBNAIL_WIDTH, UNDEFINED_SERVICE } from '../AppConstants';
import SearchResultExplanation from './SearchResultExplanation';

export const SearchResult = (
  {
    link,
    title,
    summary,
    metadata,
    thumbnail,
    onClick,
    serviceName,
    hideLink,
    queryId,
    resultRank,
    serviceRank,
    scope,
    className,
    explanation,
  }, context) => {
  const targetAttribute = context.isEmbedded ? ' target="_blank"' : '';
  const searchContext = {
    title,
    queryId,
    url: link || `app://search.warwick.ac.uk/nolink/${encodeURIComponent(title)}`,
    resultRank,
    serviceRank,
    ...scope,
  };

  const clickHandler = () => onClick(serviceName, searchContext);

  const headingHtml = {
    __html: link ? `<a${targetAttribute} href=${link}>${title}</a>` : title,
  };

  const desktopStringLength = 75;
  const mobileStringLength = 40;
  let citation = '';
  let url = '';

  if (link) {
    let availableLength = mobileStringLength;

    const linkUrl = urlModule.parse(link, true);
    const origin = linkUrl.host
      .replace(/^www2.warwick.ac.uk/, '');
    citation += origin;
    availableLength -= citation.length;

    // special case query params for map results
    const slid = linkUrl.query.slid;

    // make mobile-friendly citation
    let path = linkUrl.pathname.split('/').filter(el => (el.length)).join(' › ');
    path += slid ? ` › ${slid}` : '';

    if (path.length) {
      if (citation.length) {
        citation += ' › ';
      }
      if (path.length <= availableLength) {
        citation += path;
      } else {
        citation += `${path.substring(0, availableLength)}...`;
      }
    }
    if (!citation.length) {
      citation = 'warwick.ac.uk';
    }

    // make link URL for desktop, mid-truncated if necessary
    availableLength = desktopStringLength - origin.length - 1;
    path = linkUrl.pathname;
    path += slid ? `?slid=${slid}` : '';

    if (path.length <= availableLength) {
      url = origin + path;
    } else {
      const pathElements = path.split('/').filter(el => (el.length));

      // always show last element
      url = `.../${pathElements.pop()}`;
      availableLength -= url.length;
      url = `${origin}/${pathElements.join('/').substring(0, availableLength)}${url}`;

      // truncate if the tail was still stupid long
      if (url.length > desktopStringLength) {
        url = `${url.substring(0, desktopStringLength)}...`;
      }
    }
    if (url.length <= 1) {
      url = 'warwick.ac.uk';
    }
  }

  const linkDiv = link && !hideLink && (
    <div className="link citeUrl">
      <span className="url">{url}</span>
      <cite>{citation}</cite>
    </div>
  );

  let summaryDiv;

  if (thumbnail && thumbnail.src) {
    const alt = thumbnail.alt || 'Thumbnail';

    summaryDiv = (
      <RemoteImage
        src={thumbnail.src}
        renderFetched={({ src, image }) => {
          const { naturalWidth, naturalHeight } = image;
          // eslint-disable-next-line no-mixed-operators
          const scaledHeight = Math.floor(THUMBNAIL_WIDTH * naturalHeight / naturalWidth);
          return (
            <div className="summary">
              <a className="thumbnail-link" href={link}>
                <img src={src} alt={alt} width="150" height={scaledHeight} />
              </a>
              {summary}
            </div>
          );
        }}
        renderFailure={() => summary && (
          <div className="summary">{summary}</div>
        )}
      />
    );
  } else {
    summaryDiv = summary && (
      <div className="summary">{summary}</div>
    );
  }

  const metadataDiv = metadata && (<div className="metadata">{metadata}</div>);

  const searchResultExplanation = explanation && (
    <SearchResultExplanation explanation={explanation} />
  );

  const headerClass = summaryDiv || metadataDiv ? 'hsep' : '';
  /* eslint-disable
    react/no-danger,
    jsx-a11y/no-static-element-interactions,
    jsx-a11y/no-noninteractive-element-to-interactive-role
  */
  return (
    <li className={`search-result list-item list-unstyled ${className}`}>
      <div>
        <div className={headerClass}>
          <h4 role="link" dangerouslySetInnerHTML={headingHtml} onClick={clickHandler} />
          {linkDiv}
        </div>
        {summaryDiv}
        {metadataDiv}
        {searchResultExplanation}
      </div>

    </li>
  );
  /* eslint-enable
    react/no-danger,
    jsx-a11y/no-static-element-interactions,
    jsx-a11y/no-noninteractive-element-to-interactive-role max-len
  */
};

export default SearchResult;

SearchResult.defaultProps = {
  serviceName: UNDEFINED_SERVICE,
  onClick: () => {},
  className: '',
  link: null,
  thumbnail: {},
  hideLink: false,
  summary: null,
  metadata: null,
  resultRank: null,
  serviceRank: null,
  scope: null,
  explanation: null,
};

SearchResult.propTypes = {
  queryId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  thumbnail: PropTypes.object,
  hideLink: PropTypes.bool,
  summary: PropTypes.node,
  metadata: PropTypes.string,
  resultRank: PropTypes.number,
  serviceRank: PropTypes.number,
  scope: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  className: PropTypes.string,
  explanation: PropTypes.object,
};

SearchResult.contextTypes = {
  isEmbedded: PropTypes.bool,
};

import 'whatwg-fetch';
import {
  TimeoutError,
  timeout,
  saveError,
  saveFetchTimeout,
  getWithCredentials,
  postJsonWithCredentials,
  deleteWithCredentials,
  putJsonWithCredentials,
} from './FetchHelpers';

import {
  CURATED_LINK_REQUEST,
  CURATED_LINK_RECEIVE,
  ADD_CURATED_LINK,
  ADD_CURATED_LINK_SUCCESS,
  UPDATE_CURATED_LINK,
  UPDATE_CURATED_LINK_SUCCESS,
  DELETE_CURATED_LINK,
  DELETE_CURATED_LINK_SUCCESS,
  LOAD_CURATED_LINK_EDIT_FORM,
  CANCEL_CURATED_LINK_EDIT_FORM,
  ROUTES,
} from '../AppConstants';


// pure action creators
export function storeCuratedLink(data) {
  return {
    type: ADD_CURATED_LINK,
    data,
  };
}

export function storeCuratedLinkSuccess(data) {
  return {
    type: ADD_CURATED_LINK_SUCCESS,
    data,
  };
}

export function overwriteCuratedLink(data) {
  return {
    type: UPDATE_CURATED_LINK,
    data,
  };
}

export function overwriteCuratedLinkSuccess(data) {
  return {
    type: UPDATE_CURATED_LINK_SUCCESS,
    data,
  };
}


export function removeCuratedLink(id) {
  return {
    type: DELETE_CURATED_LINK,
    id,
  };
}

export function removeCuratedLinkSuccess(id) {
  return {
    type: DELETE_CURATED_LINK_SUCCESS,
    id,
  };
}

export function editCuratedLink(id) {
  return {
    type: LOAD_CURATED_LINK_EDIT_FORM,
    id,
  };
}

export function cancelEditCuratedLink(id) {
  return {
    type: CANCEL_CURATED_LINK_EDIT_FORM,
    id,
  };
}

export function requestResults() {
  return {
    type: CURATED_LINK_REQUEST,
  };
}

export function receiveResults(json) {
  const results = json ? json.data : null;

  return {
    type: CURATED_LINK_RECEIVE,
    results,
  };
}

// thunks
export function createCuratedLink(data) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    dispatch(storeCuratedLink(data));

    return timeout(postJsonWithCredentials(ROUTES.curatedLinkAdmin, csrfToken, data))
      .then(response => response.json())
      .then(
        json => (json.data ?
          dispatch(storeCuratedLinkSuccess(json.data)) :
          dispatch(saveError(new Error('Invalid JSON'), 'Saving curated link'))
        ),
      )
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(ROUTES.curatedLinkAdmin));
        } else {
          dispatch(saveError(error, 'Saving curated link'));
        }
      });
  };
}

export function updateCuratedLink(data) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    dispatch(overwriteCuratedLink(data));

    return timeout(putJsonWithCredentials(ROUTES.curatedLinkAdmin, csrfToken, data))
      .then(response => response.json())
      .then(
        json => (json.data ?
          dispatch(overwriteCuratedLinkSuccess(json.data)) :
          dispatch(saveError(new Error('Invalid JSON'), 'Saving curated link'))
        ),
      )
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(ROUTES.curatedLinkAdmin));
        } else {
          dispatch(saveError(error, 'Updating curated link'));
        }
      });
  };
}

export function deleteCuratedLink(id) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    dispatch(removeCuratedLink(id));

    const url = `${ROUTES.curatedLinkAdmin}/${id}`;

    return timeout(deleteWithCredentials(url, csrfToken))
      .then(
        response => ((response.status === 204) ?
          dispatch(removeCuratedLinkSuccess(id)) :
          dispatch(saveError(
            new Error(`Unexpected ${response.status} status`),
            'Deleting curated link',
          ))),
        () => dispatch(saveFetchTimeout(url)),
      );
  };
}

export function fetchCuratedLinks() {
  return (dispatch) => {
    dispatch(requestResults());

    return timeout(getWithCredentials(ROUTES.curatedLinkAdmin))
      .then(response => response.json())
      .then(json => dispatch(receiveResults(json)))
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(ROUTES.curatedLinkAdmin));
        } else {
          dispatch(saveError(error, 'Parsing JSON for curated links'));
        }
      });
  };
}

/* global document navigator */
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { isFetching } from '../selectors';
import ExamSearchFilterSelect from './ExamSearchFilterSelect';
import { ACADEMIC_YEAR_START_MONTH, EXAM_SEARCH_NUMBER_OF_YEARS } from '../AppConstants';

export class ExamSearchForm extends Component {
  static getAcademicYears(currentYear, currentMonth) {
    const academicYear = [];
    academicYear.push({ optionLabel: 'Any', optionValue: '' });

    const startYear = currentMonth >= ACADEMIC_YEAR_START_MONTH + 1 ? currentYear + 1 : currentYear;

    for (let i = 0; i < EXAM_SEARCH_NUMBER_OF_YEARS; i += 1) {
      const thisYear = startYear - i;
      const currentAcademicYear = `${thisYear - 1}-${thisYear.toString().slice(-2)}`;
      academicYear.push({ optionLabel: currentAcademicYear, optionValue: thisYear });
    }

    return academicYear;
  }

  constructor(props, context) {
    super(props, context);

    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth();

    this.state = {
      searchTerm: props.searchTerm || '',
      departmentCode: props.departmentCode || '',
      year: props.year || '',
      supportsAutofocus: ('autofocus' in document.createElement('input') &&
        !/iPad|iPhone|iPod/g.test(navigator.userAgent)), // softly, softly, patchy monkey
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
  }

  componentDidMount() {
    // push caret to end of input, if trivial
    const qInput = this.inputNode;
    if (this.props.searchTerm) {
      if (qInput.setSelectionRange) {
        const len = qInput.value.length;
        qInput.setSelectionRange(len, len);
      }
    }
    // autofocus if supported
    if (this.state.supportsAutofocus) {
      qInput.focus();
    }
  }

  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
  }

  handleDepartmentChange(e) {
    const newDepartmentCode = e.target.value;
    this.setState({ departmentCode: newDepartmentCode });
    this.props.onSubmit(this.state.searchTerm, newDepartmentCode, this.state.year);
  }

  handleYearChange(e) {
    const newYear = e.target.value;
    this.setState({ year: e.target.value });
    this.props.onSubmit(this.state.searchTerm, this.state.departmentCode, newYear);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.searchTerm || this.state.departmentCode || this.state.year) {
      this.props.onSubmit(this.state.searchTerm, this.state.departmentCode, this.state.year);
    }
    // explicitly blur (to dismiss mobile keyboard)
    if (document.activeElement) {
      document.activeElement.blur();
    }
  }

  handleClear(e) {
    e.preventDefault();
    this.setState({ searchTerm: '', departmentCode: '', year: '' });
    this.props.onClear();
  }

  render() {
    const icon = this.props.isFetching ? 'fa-spinner fa-pulse' : 'fa-search';
    const searchButtonClass = `btn-vanilla fas fa-2x searchButton ${icon}`;
    const clearButtonClass = 'btn-vanilla fas fa-2x clearButton fa-times';

    const clearButton = (
      <button className={clearButtonClass} onClick={this.handleClear}>
        <span className="sr-only">Clear</span>
      </button>
    );

    const depts = [{ optionLabel: 'Any', optionValue: '' }].concat(
      this.props.departments.map(departmentCode =>
      (({ name, code }) =>
        ({ optionLabel: name, optionValue: code }))(departmentCode),
      ),
    );

    const academicYears = ExamSearchForm.getAcademicYears(this.currentYear, this.currentMonth);

    return (
      <form className="search-form exam-papers-search-form form-horizontal" onSubmit={this.handleSubmit} action="">
        <div className="form-group-lg">
          <label className="sr-only" htmlFor="q-input">Search</label>
          <input
            type="search"
            id="q-input"
            ref={(node) => { this.inputNode = node; }}
            name="q"
            value={this.state.searchTerm}
            onChange={this.handleChange}
            className="form-control"
            placeholder="Search Warwick"
            autoComplete="off"
            autoCapitalize="off"
          />
          <button className={searchButtonClass} onClick={this.handleSubmit}>
            <span className="sr-only">Search</span>
          </button>
          {(!this.props.isFetching && this.state.searchTerm) ? clearButton : ''}
        </div>
        <ExamSearchFilterSelect
          items={academicYears}
          label="Year"
          fieldName="year"
          onChange={this.handleYearChange}
          value={this.state.year}
        />
        <ExamSearchFilterSelect
          items={depts}
          label="Departments"
          fieldName="departmentCode"
          onChange={this.handleDepartmentChange}
          value={this.state.departmentCode}
        />
      </form>
    );
  }
}

ExamSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  searchTerm: PropTypes.string,
  departmentCode: PropTypes.string,
  year: PropTypes.string,
  isFetching: PropTypes.bool,
  departments: PropTypes.array.isRequired,
};

ExamSearchForm.defaultProps = {
  searchTerm: '',
  departmentCode: '',
  departments: [],
  year: '',
  isFetching: false,
  onClear: () => null,
};

const mapStateToProps = state => ({
  isFetching: isFetching(state),
});

export default connect(mapStateToProps)(ExamSearchForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash';
import CuratedLink from './CuratedLink';
import { CuratedLinkCreateForm, CuratedLinkEditForm } from './CuratedLinkForm';

export class CuratedLinksList extends Component {
  render() {
    const { onSubmit, onDelete, onEdit, onCancelEdit } = this.props;
    let renderedResults;
    let isDisplayNewRecordForm = true;

    if (this.props.results.length) {
      renderedResults = this.props.results.map(
        (result) => {
          let renderedResultRow;
          if (result.id === this.props.editing) {
            isDisplayNewRecordForm = false;
            renderedResultRow = (
              <CuratedLinkEditForm
                initialValues={result}
                onSubmit={onSubmit}
                onCancelEdit={onCancelEdit}
                key={uniqueId('curatedLink')}
              />
            );
          } else {
            renderedResultRow = (
              <CuratedLink
                result={result}
                key={uniqueId('curatedLink')}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            );
          }
          return renderedResultRow;
        },
      );
    }

    const newRecordForm = isDisplayNewRecordForm ?
      (<CuratedLinkCreateForm onSubmit={onSubmit} />) : null;

    return (
      <div className="form-table">
        <div className="header row">
          <b className="col-sm-4">Title</b>
          <b className="col-sm-4">URL</b>
          <b className="col-sm-4">Weighting</b>
        </div>
        {renderedResults}
        {newRecordForm}
      </div>
    );
  }
}

CuratedLinksList.propTypes = {
  editing: PropTypes.string,
  results: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
};

CuratedLinksList.defaultProps = {
  editing: null,
};

const mapStateToProps = state => ({
  results: state.curatedLink.results,
  fetchState: state.curatedLink.state,
  editing: state.curatedLink.editing,
});

export default connect(mapStateToProps)(CuratedLinksList);

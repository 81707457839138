import 'babel-polyfill';
import { FastClick } from 'fastclick';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { userInfo } from './actions/user';
import * as log from './utils/logging';

import store from './store';
import App from './containers/App';

log.info('Starting app');

FastClick.attach(document.body);

store.dispatch(userInfo());

const mountPoint = document.getElementById('react-mount-point');

if (mountPoint) {
  render(<Provider store={store}><App /></Provider>, mountPoint);
}

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, pick } from 'lodash';

import {
  SCOPE_ARGUMENTS,
  EXAM_SEARCH_SERVICE,
} from '../AppConstants';

import * as search from '../actions/search';
import * as componentFunctionsHack from '../component_functions';

import DocumentTitle from '../components/DocumentTitle';
import ExamSearchResults from '../components/ExamSearchResults';
import NoExamSearchResults from '../components/NoExamSearchResults';
import ResultsInfo from '../components/ResultsInfo';
import ExamSearchForm from '../components/ExamSearchForm';
import { validLengthTerm } from '../utils/index';

export class ExamSearch extends Component {
  constructor(props, context) {
    super(props, context);

    this.submitSearch = this.submitSearch.bind(this);
    this.resubmitWithoutScope = this.resubmitWithoutScope.bind(this);
    this.onClear = this.onClear.bind(this);

    // Hack to allow initiating a search from outside of React.
    componentFunctionsHack.setSubmitSearchFunction(this.submitSearch);

    this.resultsComponents = [
      <ExamSearchResults key="examSearch" />,
    ];

    if (props.userLoaded) {
      props.dispatch(search.runEndpointQuery(props.query, EXAM_SEARCH_SERVICE));
    }

    if (props.userLoaded && props.departments.length === 0) {
      this.props.dispatch(search.fetchDepartmentsWithExamPapers());
    }
  }

  componentWillReceiveProps(nextProps) {
    const newQuery = nextProps.query;
    const oldQuery = this.props.query;

    // run query if finished authenticating
    let doRun = !isEqual(this.props.userLoaded, nextProps.userLoaded);

    // or search term changed
    doRun = doRun || (
      !isEqual(oldQuery.q, newQuery.q) ||
      !isEqual(oldQuery.departmentCode, newQuery.departmentCode) ||
      !isEqual(oldQuery.moduleCode, newQuery.moduleCode) ||
      !isEqual(oldQuery.year, newQuery.year)
    );

    // or scope changed
    const oldScope = pick(oldQuery, SCOPE_ARGUMENTS);
    const newScope = pick(newQuery, SCOPE_ARGUMENTS);
    doRun = doRun || !isEqual(oldScope, newScope);

    if (doRun) {
      this.props.dispatch(search.runEndpointQuery(newQuery, EXAM_SEARCH_SERVICE));
    }

    if (this.props.userLoaded && this.props.departments.length === 0) {
      this.props.dispatch(search.fetchDepartmentsWithExamPapers());
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.query, this.props.query) ||
      !isEqual(nextProps.departments, this.props.departments) ||
      !isEqual(nextProps.isAuthenticated, this.props.isAuthenticated) ||
      !isEqual(nextProps.signinLink, this.props.signinLink);
  }

  onClear() {
    this.props.router.push({
      ...this.props.location,
      query: {},
    });
  }

  submitSearch(searchTerm, departmentCode, year) {
    if ((searchTerm && validLengthTerm(searchTerm)) ||
        (departmentCode && validLengthTerm(departmentCode)) ||
        (year && validLengthTerm(year))) {
      const validArguments = SCOPE_ARGUMENTS.concat(['debug']);
      const validFoundArguments = pick(this.props.query, validArguments);
      const query = { q: searchTerm, ...validFoundArguments, year, departmentCode };
      const newLocation = { ...this.props.location, query };
      if (!isEqual(newLocation, this.props.location)) {
        this.props.router.push(newLocation);
      }
    }
  }

  resubmitWithoutScope() {
    const searchTerm = this.props.query.q;
    const departmentCode = this.props.query.departmentCode;
    const year = this.props.query.year;
    const debug = !!this.props.location.query.debug;
    const query = { q: searchTerm, departmentCode, year, debug };
    const newLocation = { ...this.props.location, query };
    this.props.router.push(newLocation);
  }

  render() {
    const { query, showSearchForm, departments, isAuthenticated, signinLink } = this.props;
    const { q, departmentCode, year } = query;
    const documentTitle = q ? `${q} - Warwick Search` : 'Warwick Search';

    const notLoggedInBody = (
      <div className="not-logged-in">
        <p>You need to <a href={signinLink}>sign in</a> to view this content.</p>
      </div>
    );

    const examSearchBody = (
      <div>
        <DocumentTitle title={documentTitle} />
        <div>
          {showSearchForm ?
            <ExamSearchForm
              searchTerm={q || ''}
              year={year || ''}
              departmentCode={departmentCode || ''}
              departments={departments || []}
              onSubmit={this.submitSearch}
              onClear={this.onClear}
            />
            : null}
          <ResultsInfo resubmitWithoutScope={this.resubmitWithoutScope} />
          {this.resultsComponents}
          <NoExamSearchResults />
        </div>
      </div>
    );

    return isAuthenticated ? examSearchBody : notLoggedInBody;
  }
}

function mapStateToProps(state, ownProps) {
  const { location } = ownProps;
  const { query } = location;

  return {
    userLoaded: state.sso.user.authoritative,
    isAuthenticated: state.sso.user.authenticated,
    signinLink: state.sso.links.login,
    query,
    departments: state.search.departments,
  };
}

export default connect(mapStateToProps)(withRouter(ExamSearch));

ExamSearch.defaultProps = {
  showSearchForm: true,
  userLoaded: false,
  isAuthenticated: false,
  signinLink: null,
  query: {},
  departments: [],
};

ExamSearch.propTypes = {
  // overrideable by mywarwick:
  showSearchForm: PropTypes.bool,
  // supplied by redux:
  dispatch: PropTypes.func.isRequired,
  // supplied by react-router:
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // supplied by mapStateToProps:
  userLoaded: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  signinLink: PropTypes.string,
  query: PropTypes.object,
  departments: PropTypes.array,
};

ExamSearch.contextTypes = {
  isEmbedded: PropTypes.bool,
};

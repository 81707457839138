import { mapValues, once } from 'lodash';

// routing actions
export const SET_PAGE = 'SET_PAGE';

// search actions
export const STORE_SEARCH_TERM = 'STORE_SEARCH_TERM';
export const STORE_SEARCH_SCOPE = 'STORE_SEARCH_SCOPE';
export const STORE_COUNT = 'STORE_COUNT';
export const MORE_BUTTON_CLICKED = 'MORE_BUTTON_CLICKED';
export const QUERY_ID_RECEIVE = 'QUERY_ID_RECEIVE';
export const RESULTS_REQUEST = 'RESULTS_REQUEST';
export const RESULTS_RECEIVE = 'RESULTS_RECEIVE';
export const RESULTS_CLEAR = 'RESULTS_CLEAR';
export const LOG_ERROR = 'LOG_ERROR';
export const LOG_TIMEOUT = 'LOG_TIMEOUT';


// user actions
export const USER_REQUEST = 'USER_REQUEST';
export const USER_RECEIVE = 'USER_RECEIVE';
export const SSO_LINKS_RECEIVE = 'SSO_LINKS_RECEIVE';
export const CSRF_TOKEN_RECEIVE = 'CSRF_TOKEN_RECEIVE';

// curated link actions
export const ADD_CURATED_LINK = 'ADD_CURATED_LINK';
export const ADD_CURATED_LINK_SUCCESS = 'ADD_CURATED_LINK_SUCCESS';
export const DELETE_CURATED_LINK = 'DELETE_CURATED_LINK';
export const DELETE_CURATED_LINK_SUCCESS = 'DELETE_CURATED_LINK_SUCCESS';
export const UPDATE_CURATED_LINK = 'UPDATE_CURATED_LINK';
export const UPDATE_CURATED_LINK_SUCCESS = 'UPDATE_CURATED_LINK_SUCCESS';
export const CURATED_LINK_REQUEST = 'CURATED_LINK_REQUEST';
export const CURATED_LINK_RECEIVE = 'CURATED_LINK_RECEIVE';
export const LOAD_CURATED_LINK_EDIT_FORM = 'LOAD_CURATED_LINK_EDIT_FORM';
export const CANCEL_CURATED_LINK_EDIT_FORM = 'CANCEL_CURATED_LINK_EDIT_FORM';

// exam search actions
export const DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE = 'DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE';

// routes
export const ROUTES = {
  userInfo: '/service/user',
  masquerade: '/sso/masquerade',
  unmask: '/sso/unmask',
  blogSearch: '/api/blog',
  examSearch: '/api/exam',
  goSearch: '/api/go',
  mapSearch: '/api/maps',
  peopleSearch: '/api/peoplesearch',
  webSearch: '/api/sitebuilder',
  wrapSearch: '/api/wrap',
  suggestedLinks: '/api/suggested-links',
  searchHistory: '/api/search-history',
  clickHistory: '/api/click-history',
  saveError: '/api/save-error',
  saveTimeout: '/api/save-timeout',
  curatedLinkAdmin: '/api/admin/curated-links',
  departmentsWithExamPapers: '/api/exam/departments',
};

export const prependToRoutes = once((rootUrl) => {
  const prependedRoutes = mapValues(ROUTES, path => `${rootUrl}${path}`);
  Object.assign(ROUTES, prependedRoutes); // eslint-disable-line warwick/no-object-assign
});

// enumerated fetch results
const IDLE = 'IDLE';
const FETCHING = 'FETCHING';
const FAILED = 'FAILED';
export const FETCH_STATES = { IDLE, FETCHING, FAILED };

// enumerated search result batch types
const NO_MATCHES = 'NO_MATCHES';
const FIRST_RESULTS = 'FIRST_RESULTS';
const SUBSEQUENT_RESULTS = 'SUBSEQUENT_RESULTS';
const LAST_RESULTS = 'LAST_RESULTS';
const NO_MORE_RESULTS = 'NO_MORE_RESULTS';
const FAILED_SEARCH = 'FAILED_SEARCH';
const UNBATCHED = 'UNBATCHED';
export const BATCH_TYPES = {
  NO_MATCHES,
  FIRST_RESULTS,
  SUBSEQUENT_RESULTS,
  LAST_RESULTS,
  NO_MORE_RESULTS,
  FAILED_SEARCH,
  UNBATCHED,
};

// default api timeout
export const API_TIMEOUT_PERIOD = 6000;

// default thumbnail width
export const THUMBNAIL_WIDTH = 150;

// search term length limits
export const MIN_SEARCH_TERM_LENGTH = 1;
export const MAX_SEARCH_TERM_LENGTH = 2048;

// search services
export const WEBSERVICES = {
  blogSearch: {
    title: 'Warwick Blogs',
    description: 'blogs',
    showBestResultOnly: false,
    arguments: {
      prefix: 'b_',
      acceptPrefixed: ['count'],
      accept: ['q', 'urlPrefix', 'dateRange', 'debug'],
      deny: [],
    },
  },
  examSearch: {
    title: 'Exam Papers',
    description: 'papers',
    showBestResultOnly: false,
    arguments: {
      prefix: 'e_',
      acceptPrefixed: ['count'],
      accept: ['q', 'departmentCode', 'moduleCode', 'year', 'debug'],
      deny: ['urlPrefix', 'dateRange'],
    },
  },
  goSearch: {
    title: 'Suggested Results',
    description: 'suggestions',
    showBestResultOnly: true,
    foundBestMatch: false,
    arguments: {
      prefix: 'g_',
      acceptPrefixed: ['count'],
      accept: ['q'],
      deny: ['urlPrefix', 'dateRange', 'debug'],
    },
  },
  mapSearch: {
    title: 'Campus Map',
    description: 'places',
    showBestResultOnly: false,
    arguments: {
      prefix: 'n_',
      acceptPrefixed: ['count'],
      accept: ['q'],
      deny: ['urlPrefix', 'dateRange'],
    },
  },
  peopleSearch: {
    title: 'People',
    description: 'people',
    showBestResultOnly: false,
    arguments: {
      prefix: 'p_',
      acceptPrefixed: ['count'],
      accept: ['q'],
      deny: ['urlPrefix', 'dateRange', 'debug'],
    },
  },
  suggestedLinks: {
    arguments: {
      deny: ['q'],
    },
  },
  webSearch: {
    title: 'Website Search Results',
    description: 'results',
    showBestResultOnly: false,
    arguments: {
      prefix: 'w_',
      acceptPrefixed: ['count'],
      accept: ['q', 'urlPrefix', 'dateRange', 'debug'],
      deny: [],
    },
  },
  wrapSearch: {
    title: 'Warwick Research Archive Results',
    description: 'results',
    showBestResultOnly: false,
    arguments: {
      prefix: 'wr_',
      acceptPrefixed: ['count'],
      accept: ['q', 'urlPrefix', 'dateRange', 'debug'],
      deny: [],
    },
  },
};

export const EXAM_SEARCH_SERVICE = '/api/exam-es/papers:examSearch';
export const ACADEMIC_YEAR_START_MONTH = 9;
export const EXAM_SEARCH_NUMBER_OF_YEARS = 5;

export const UNDEFINED_SERVICE = 'Undefined Service';

export const SCOPE_ARGUMENTS = ['urlPrefix', 'dateRange', 'departmentCode', 'year', 'moduleCode'];

export const ENDPOINT_REGEX = ['^/api/[-a-z0-9]+(:[a-zA-Z]+)?$'];

// RetrievalBatchSize in API.scala = DISPLAY_BATCH_SIZE
export const DISPLAY_BATCH_SIZE = 10;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchResults from './SearchResults';
import ExamSearchResult from './ExamSearchResult';

const examFooter = 'Papers are provided for personal, private study purposes only. ' +
  'Not for distribution in any form. Module content changes from year to year. ' +
  'Past papers may not reflect current content. ' +
  'See <a href="http://warwick.ac.uk/exampapers">this page for more details</a>.';

export const ExamSearchResults = ({ ...props }) => {
  if (props.results.length) {
    return (
      <SearchResults
        {...props}
        ResultComponent={ExamSearchResult}
        footer={examFooter}
      />
    );
  }

  return false;
};

ExamSearchResults.propTypes = {
  results: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  results: state.search.services.examSearch.results,
});

export default connect(mapStateToProps)(ExamSearchResults);

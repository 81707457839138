/* global document navigator */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFetching } from '../selectors';
import WelcomeHint from './WelcomeHint';

export class SearchForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      searchTerm: props.searchTerm || '',
      supportsAutofocus: ('autofocus' in document.createElement('input') &&
        !/iPad|iPhone|iPod/g.test(navigator.userAgent)), // softly, softly, patchy monkey
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    // push caret to end of input, if trivial
    const qInput = this.inputNode;
    if (this.props.searchTerm) {
      if (qInput.setSelectionRange) {
        const len = qInput.value.length;
        qInput.setSelectionRange(len, len);
      }
    }
    // autofocus if supported
    if (this.state.supportsAutofocus) {
      qInput.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm !== this.state.searchTerm) {
      this.setState({ searchTerm: nextProps.searchTerm });
    }
  }

  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.searchTerm) {
      this.props.onSubmit(this.state.searchTerm);
    }
    // explicitly blur (to dismiss mobile keyboard)
    if (document.activeElement) {
      document.activeElement.blur();
    }
  }

  handleClear(e) {
    e.preventDefault();
    this.setState({ searchTerm: '' });
    this.props.onClear();
  }

  render() {
    const icon = this.props.isFetching ? 'fa-spinner fa-pulse' : 'fa-search';
    const searchButtonClass = `btn-vanilla fas fa-2x searchButton ${icon}`;
    const clearButtonClass = 'btn-vanilla fas fa-2x clearButton fa-times';

    const clearButton = (
      <button className={clearButtonClass} onClick={this.handleClear}>
        <span className="sr-only">Clear</span>
      </button>
    );

    return (
      <form className="search-form" onSubmit={this.handleSubmit} action="">
        <div className="form-group-lg">
          <label className="sr-only" htmlFor="q-input">Search</label>
          <input
            type="search"
            id="q-input"
            ref={(node) => { this.inputNode = node; }}
            name="q"
            value={this.state.searchTerm}
            onChange={this.handleChange}
            className="form-control"
            placeholder="Search Warwick"
            autoComplete="off"
            autoCapitalize="off"
          />
          <button className={searchButtonClass} onClick={this.handleSubmit}>
            <span className="sr-only">Search</span>
          </button>
          {(!this.props.isFetching && this.state.searchTerm) ? clearButton : ''}
        </div>
        <WelcomeHint />
      </form>
    );
  }
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  searchTerm: PropTypes.string,
  isFetching: PropTypes.bool,
};

SearchForm.defaultProps = {
  searchTerm: '',
  isFetching: false,
  onClear: () => null,
};

const mapStateToProps = state => ({
  isFetching: isFetching(state),
});

export default connect(mapStateToProps)(SearchForm);

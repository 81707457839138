/**
 * I'm so sorry about this
 */

let searchFn = function notSetYet() {
  throw new Error('submitSearch called before setSubmitSearchFunction was called');
};

export function setSubmitSearchFunction(func) {
  searchFn = func;
}

export function submitSearch(searchTerm) {
  searchFn(searchTerm);
}

/* global window */

import 'whatwg-fetch';
import { isWebUri } from 'valid-url';
import {
  TimeoutError,
  timeout,
  getWithCredentials,
  postWithCredentials,
  saveError,
  saveFetchTimeout,
} from './FetchHelpers';
import * as log from '../utils/logging';

import {
  USER_REQUEST,
  USER_RECEIVE,
  SSO_LINKS_RECEIVE,
  CSRF_TOKEN_RECEIVE,
  ROUTES,
} from '../AppConstants';

const redirect = (url) => {
  window.location.href = url;
};

const reload = () => {
  window.location.reload();
};

export function userRequest() {
  return { type: USER_REQUEST };
}

export function receiveUser(user) {
  return { type: USER_RECEIVE, user };
}

export function receiveSSOLinks(links) {
  return { type: SSO_LINKS_RECEIVE, links };
}

export function receiveCsrfToken(token) {
  return { type: CSRF_TOKEN_RECEIVE, token };
}

export function userInfo() {
  return (dispatch) => {
    dispatch(userRequest());

    return timeout(getWithCredentials(ROUTES.userInfo))
      .then(response => (response.json()))
      .then(
        (json) => {
          if (!json.refresh && json.user && json.links && json.token) {
            dispatch(receiveUser(json.user));
            dispatch(receiveSSOLinks(json.links));
            dispatch(receiveCsrfToken(json.token));
          } else if (isWebUri(json.refresh)) {
            redirect(json.refresh);
          } else {
            log.error(`Unexpected response from ${ROUTES.userInfo}`, json);
            dispatch(saveError(new Error(`Unexpected response from ${ROUTES.userInfo}`)), 'Parsing user');
          }
        },
      )
      .catch((error) => {
        if (error instanceof TimeoutError) {
          dispatch(saveFetchTimeout(ROUTES.userInfo));
        } else {
          log.error('Error parsing user:', error);
          dispatch(saveError(error, 'Parsing user'));
        }
      });
  };
}

export function masquerade(usercode) {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    const formData = new FormData();
    formData.append('usercode', usercode);
    formData.append('csrfToken', csrfToken);
    postWithCredentials(ROUTES.masquerade, csrfToken, formData)
      .then(() => {
        reload();
      });
  };
}

export function stopMasquerading() {
  return (dispatch, getState) => {
    const csrfToken = getState && getState().sso ? getState().sso.csrfToken : null;
    postWithCredentials(ROUTES.unmask, csrfToken)
      .then(() => {
        reload();
      });
  };
}

import {
  MIN_SEARCH_TERM_LENGTH,
  MAX_SEARCH_TERM_LENGTH,
} from '../AppConstants';

export const inRange = (str, min, max) =>
  (str != null && str.trim().length >= min && str.trim().length <= max);

export const validLengthTerm = str =>
  inRange(str, MIN_SEARCH_TERM_LENGTH, MAX_SEARCH_TERM_LENGTH);

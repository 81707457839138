import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MoreResultsButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isFetching: props.isFetching,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isFetching: nextProps.isFetching });
  }

  render() {
    const { isFetching } = this.state;
    const { onClick, label } = this.props;
    const handleClickOnce = (e) => {
      if (!isFetching) {
        this.setState({ isFetching: true });
        onClick(e);
      }
    };
    const className = `btn btn-default more-results${isFetching ? ' disabled' : ''}`;

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <a
        role="button"
        tabIndex={0}
        className={className}
        onClick={handleClickOnce}
      >
        { isFetching ? <i className="fa fa-fw fa-lg fa-pulse fa-spinner" /> : null } {label}
      </a>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

MoreResultsButton.propTypes = {
  isFetching: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

MoreResultsButton.defaultProps = {
  isFetching: false,
  label: 'More results',
};

import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from './SearchResult';

export const ExamSearchResult = ({ result, ...other }) => {
  const { title, code, sessions } = result;
  const maxSessions = 5;
  const renderedTitle = `<span class="course-code">${code}</span> ${title}`;

  if (!sessions.length) {
    return null;
  }

  const latestSessions = sessions.slice(0, maxSessions);
  let summaryHtml = '<table class="table exam-papers"><thead><tr>';

  for (let i = 0; i < maxSessions; i += 1) {
    if (i in latestSessions) {
      summaryHtml += `<th>${latestSessions[i].year}</th>`;
    } else {
      summaryHtml += '<th></th>';
    }
  }
  summaryHtml += '</tr></thead><tbody><tr>';

  for (let i = 0; i < maxSessions; i += 1) {
    if (i in latestSessions) {
      summaryHtml += `<td data-th="${latestSessions[i].year}"><ul>`;

      const papers = latestSessions[i].papers;
      for (let j = 0; j < papers.length; j += 1) {
        summaryHtml += `<li><a href="${papers[j].url}">${papers[j].id}</a></li>`;
      }

      summaryHtml += '</ul></td>';
    } else {
      summaryHtml += '<td></td>';
    }
  }
  summaryHtml += '</tr></tbody></table>';

  const summary = (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: summaryHtml }} />
  );

  const { explanation } = result;

  return (
    <SearchResult
      {...other}
      title={renderedTitle}
      summary={summary}
      className="exam-result"
      explanation={explanation}
    />
  );
};

export default ExamSearchResult;

ExamSearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import validateCuratedLink from '../../validators/validateCuratedLink';

// redux-form v5 hack - https://github.com/erikras/redux-form/issues/1441
/* eslint-disable no-unused-vars */
const domOnlyProps = ({
  initialValue,
  autofill,
  onUpdate,
  valid,
  invalid,
  dirty,
  pristine,
  active,
  error,
  touched,
  visited,
  autofilled,
  ...domProps
}) => domProps;
/* eslint-enable no-unused-vars */

export const CuratedLinkForm = (props) => {
  const {
    fields: { id, title, url, weighting }, submitting, handleSubmit, onCancelEdit,
  } = props;

  const isEdit = onCancelEdit != null;
  const saveLabel = isEdit ? 'Update' : 'Add';

  const errorClasses = {
    title: title.error && title.touched ? 'has-error' : '',
    url: url.error && url.touched ? 'has-error' : '',
    weighting: weighting.error && weighting.touched ? 'has-error' : '',
  };

  return (
    <fieldset className="row">
      <div className={`form-group col-sm-4 ${errorClasses.title}`}>
        <input type="hidden" id={id} />
        <input type="text" className="form-control" {...domOnlyProps(title)} placeholder="Title" />
        {title.error && title.touched && <div className="small help-block">{title.error}</div>}
      </div>

      <div className={`form-group col-sm-4 ${errorClasses.url}`}>
        <input type="text" className="form-control" {...domOnlyProps(url)} placeholder="URL" />
        {url.error && url.touched && <div className="small help-block">{url.error}</div>}
      </div>

      <div className={`form-group col-sm-1 ${errorClasses.weighting}`}>
        <input
          type="text"
          className="form-control"
          {...domOnlyProps(weighting)}
          placeholder="Weighting"
        />
        {weighting.error && weighting.touched &&
          <div className="small help-block">{weighting.error}</div>
        }
      </div>

      <div className="col-sm-3">
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-success"
          disabled={submitting}
        >
          {saveLabel}
        </button>

        {isEdit &&
          <button type="button" className="btn btn-default" onClick={onCancelEdit}>Cancel</button>}
      </div>
    </fieldset>
  );
};

export const CuratedLinkEditForm = reduxForm({
  form: 'curatedLinkEditForm',
  fields: ['id', 'title', 'url', 'weighting'],
  validate: validateCuratedLink,
})(CuratedLinkForm);

export const CuratedLinkCreateForm = reduxForm({
  form: 'curatedLinkCreateForm',
  fields: ['id', 'title', 'url', 'weighting'],
  validate: validateCuratedLink,
})(CuratedLinkForm);


CuratedLinkForm.propTypes = {
  onCancelEdit: PropTypes.func,
  fields: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  initialValues: PropTypes.object,
};

CuratedLinkForm.defaultProps = {
  onCancelEdit: () => null,
  fields: {},
  submitting: false,
  handleSubmit: () => null,
  initialValues: {},
};

export default CuratedLinkForm;

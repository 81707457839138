import { concat, forOwn, isEqual, isFinite, pick, uniqBy } from 'lodash';

import {
  STORE_SEARCH_TERM,
  STORE_SEARCH_SCOPE,
  STORE_COUNT,
  MORE_BUTTON_CLICKED,
  QUERY_ID_RECEIVE,
  RESULTS_REQUEST,
  RESULTS_RECEIVE,
  RESULTS_CLEAR,
  FETCH_STATES,
  WEBSERVICES,
  BATCH_TYPES,
  SCOPE_ARGUMENTS,
  DISPLAY_BATCH_SIZE,
  DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE,
} from '../AppConstants';

const initialServices = {};

forOwn(WEBSERVICES, (_, service) => {
  initialServices[service] = {
    state: FETCH_STATES.IDLE,
    results: [],
    resultsToShow: DISPLAY_BATCH_SIZE,
    isComplete: false,
    isMoreResultsButtonClicked: false,
  };
});

const initialState = {
  searchTerm: '',
  scope: {},
  queryId: '',
  services: initialServices,
};

export default function search(state = initialState, action) {
  let services = {};

  switch (action.type) {
    case STORE_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    }

    case STORE_SEARCH_SCOPE: {
      return {
        ...state,
        scope: action.scope,
      };
    }

    case QUERY_ID_RECEIVE: {
      return {
        ...state,
        queryId: action.id,
      };
    }

    case STORE_COUNT: {
      const service = state.services[action.service];
      let resultsToShow = parseInt(action.resultsToShow, 10);
      if (!isFinite(resultsToShow)) {
        resultsToShow = DISPLAY_BATCH_SIZE;
      }

      services = {
        ...state.services,
        [action.service]: {
          ...service,
          resultsToShow,
        },
      };

      return {
        ...state,
        services,
      };
    }

    case MORE_BUTTON_CLICKED: {
      const service = state.services[action.service];

      services = {
        ...state.services,
        [action.service]: {
          ...service,
          isMoreResultsButtonClicked: true,
        },
      };

      return {
        ...state,
        services,
      };
    }

    case RESULTS_REQUEST: {
      const service = state.services[action.service];
      const endpoint = action.endpoint;
      services = {
        ...state.services,
        [action.service]: {
          ...service,
          endpoint,
          state: FETCH_STATES.FETCHING,
        },
      };

      const newScope = pick(action.query, SCOPE_ARGUMENTS);
      const oldScope = state.scope;

      if ((action.query && action.query.q && !isEqual(action.query.q, state.searchTerm)) ||
        !isEqual(newScope, oldScope)) {
        // query changed
        services[action.service].results = [];
      }

      return {
        ...state,
        services,
      };
    }

    case RESULTS_RECEIVE: {
      const service = state.services[action.service];
      if (service && service.state === FETCH_STATES.FETCHING) {
        let serviceData;

        switch (action.batchType) {
          case BATCH_TYPES.NO_MATCHES:
            serviceData = {
              ...service,
              state: FETCH_STATES.IDLE,
              results: [],
              isComplete: true,
            };
            break;

          case BATCH_TYPES.UNBATCHED:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.IDLE,
              results: action.results,
              isComplete: true,
            };
            break;

          case BATCH_TYPES.FIRST_RESULTS:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.IDLE,
              results: action.results,
              isComplete: false,
            };
            break;

          case BATCH_TYPES.SUBSEQUENT_RESULTS:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.IDLE,
              results: state.searchTerm !== action.searchTerm ? action.results :
                uniqBy(concat(service.results, action.results), 'link'),
              isComplete: false,
            };
            break;

          case BATCH_TYPES.LAST_RESULTS:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.IDLE,
              results: state.searchTerm !== action.searchTerm ? action.results :
                uniqBy(concat(service.results, action.results), 'link'),
              isComplete: true,
            };
            break;

          case BATCH_TYPES.NO_MORE_RESULTS:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.IDLE,
              isComplete: true,
            };
            break;

          default:
            serviceData = {
              ...state.services[action.service],
              state: FETCH_STATES.FAILED,
            };
        }

        services = {
          ...state.services,
          [action.service]: serviceData,
        };
      } else {
        services = {
          ...state.services,
        };
      }

      return {
        ...state,
        services,
      };
    }

    case RESULTS_CLEAR: {
      services = {
        ...state.services,
        [action.service]: {
          state: FETCH_STATES.IDLE,
          results: [],
          resultsToShow: DISPLAY_BATCH_SIZE,
          isComplete: false,
          isMoreResultsButtonClicked: false,
        },
      };

      return {
        ...state,
        services,
      };
    }

    case DEPARTMENTS_WITH_EXAM_PAPERS_RECEIVE: {
      services = {
        ...state.services,
      };

      return {
        ...state,
        services,
        departments: action.results,
      };
    }

    default:
      return state;
  }
}

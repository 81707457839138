/* global Image */

/**
 * Extracted from react-remote-image@0.2.8, which had a bunch of bad dependencies.
 * Refactored for lint-compliance.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, isEmpty, isString, omit, startsWith } from 'lodash';

function getURLWithSalt(URL) {
  if (isString(URL) && !isEmpty(URL) && !startsWith(URL, 'data:')) {
    const salt = Math.floor((Date.now() + Math.random()) * 100);
    if (includes(URL, '?')) {
      return `${URL}&_=${salt}`;
    }
    return `${URL}?_=${salt}`;
  }
  return URL;
}

export default class RemoteImage extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleRetry = this.fetchImage.bind(this);
    const src = props.forceFetch ? getURLWithSalt(props.src) : props.src;
    const isEmptySrc = isEmpty(src);
    // Warning if source is empty, only for develop
    if (process.env.NODE_ENV !== 'production') {
      if (isEmptySrc) {
        // eslint-disable-next-line no-console
        console.warn('Image src is not a string', src);
      }
    }
    this.state = {
      src,
      isLoading: true,
      isFailed: false,
      isEmptySrc,
      image: null,
      ...props,
    };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.fetchImage();
  }

  componentWillReceiveProps(nextProps) {
    const { src, forceFetch } = this.props;
    const rawSrc = (nextProps.src !== src) ? nextProps.src : src;
    const hasSalt = (nextProps.forceFetch !== forceFetch) ? nextProps.forceFetch : forceFetch;
    const finalSrc = hasSalt ? getURLWithSalt(rawSrc) : rawSrc;
    if (finalSrc !== this.state.src) {
      this.clearEvents();
      this.setState({
        src: finalSrc,
        isLoading: true,
        isFailed: false,
        isEmptySrc: isEmpty(src),
      }, () => this.fetchImage());
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.src !== this.props.src;
  }

  componentWillUnmount() {
    this.clearEvents();
  }

  onLoad() {
    this.setState({
      isLoading: false,
      isFailed: false,
    }, this.props.onLoad);
  }

  onError() {
    this.clearEvents();
    this.setState({
      isLoading: false,
      isFailed: true,
    }, this.props.onError);
  }

  clearEvents() {
    const { image } = this.state;
    if (image) {
      image.removeEventListener('load', this.onLoad);
      image.removeEventListener('error', this.onError);
    }
  }

  fetchImage() {
    const { src, isEmptySrc } = this.state;
    if (!isEmptySrc) {
      const image = new Image();
      image.addEventListener('load', this.onLoad);
      image.addEventListener('error', this.onError);
      image.src = src;
      this.setState({
        image,
      });
    }
  }

  render() {
    const { isLoading, isFailed, isEmptySrc, src, image } = this.state;
    const { renderLoading, renderFetched, renderFailure, ...otherProps } = this.props;
    if (isLoading && !isEmptySrc) {
      if (renderLoading) {
        return renderLoading({ src, image });
      }
    } else if (isFailed || isEmptySrc) {
      if (renderFailure) {
        return renderFailure('unable to load image', this.handleRetry);
      }
    } else if (renderFetched) {
      return renderFetched({ src, image });
    }

    const passthroughProps = omit(otherProps, ['forceFetch']);

    return (
      <img {...passthroughProps} src={src} alt="" />
    );
  }
}

RemoteImage.propTypes = {
  src: PropTypes.string,
  forceFetch: PropTypes.bool,
  renderLoading: PropTypes.func,
  renderFetched: PropTypes.func,
  renderFailure: PropTypes.func,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
};

RemoteImage.defaultProps = {
  src: null,
  forceFetch: false,
  renderLoading: null,
  renderFetched: null,
  renderFailure: null,
  onLoad: () => null,
  onError: () => null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import ID7Layout from './ID7Layout';
import UtilityBar from './UtilityBar';

export default class Layout extends Component {
  getChildContext() {
    return {
      isEmbedded: false,
      location: this.props.location,
    };
  }

  shouldComponentUpdate(nextProps) {
    return (!isEqual(nextProps, this.props));
  }

  render() {
    const { children } = this.props;

    return (
      <ID7Layout utilityBar={<UtilityBar />}>
        {children}
      </ID7Layout>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
};

Layout.childContextTypes = {
  isEmbedded: PropTypes.bool,
  location: PropTypes.object,
};

Layout.defaultProps = {
  children: null,
};

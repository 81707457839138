import { isEmpty } from 'lodash';
import isUrl from 'is-url';

export default function validateCuratedLink(data) {
  const errors = {};

  if (isEmpty(data) || isEmpty(data.url)) {
    errors.url = 'Required';
  } else if (!isUrl(data.url)) {
    errors.url = 'URL must be valid';
  }

  // FIXME deal with title conflict within the whole dataset
  if (isEmpty(data) || isEmpty(data.title)) {
    errors.title = 'Required';
  } else if (data.title.length > 200) {
    errors.title = 'Over 200 characters';
  }

  if (
    isEmpty(data) ||
    !Object.keys(data).includes('weighting') ||
    data.weighting == null ||
    data.weighting.length === 0
  ) {
    errors.weighting = 'Required';
  } else if (!/^1?\d{1,3}(\.\d)?$/.test(data.weighting)) {
    errors.weighting = '0.0 - 1000.0';
  }

  return errors;
}


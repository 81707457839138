import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import { whyDidYouUpdate } from 'why-did-you-update';

import DocumentTitle from '../components/DocumentTitle';
import store from '../store';
import Layout from '../components/Layout';
import NoLayout from '../components/NoLayout';
import Search from './Search';
import ExamSearch from './ExamSearch';
import Admin from '../components/admin/Admin';
import CuratedLinks from './CuratedLinks';
import Masquerade from './Masquerade';

class App extends Component {
  constructor(props, context) {
    const { history, isEmbedded } = props;

    super(props, context);

    this.isMonkeyPatched = false;
    this.isDebug = false;

    history.listen((location) => {
      if (location.query.debug) {
        this.isDebug = true;
      }

      if (process.env.NODE_ENV !== 'production' && this.isDebug && !this.isMonkeyPatched) {
        // eslint-disable-next-line no-unused-vars
        let createClass = React.createClass;
        Object.defineProperty(React, 'createClass', {
          set: (nextCreateClass) => {
            createClass = nextCreateClass;
          },
        });
        whyDidYouUpdate(React);
        this.isMonkeyPatched = true;
      }
    });

    this.history = syncHistoryWithStore(history, store);

    this.routeConfig = [{
      path: '/',
      component: isEmbedded ? NoLayout : Layout,
      indexRoute: {
        component: Search,
      },
      childRoutes: [{
        path: 'admin',
        indexRoute: {
          component: Admin,
        },
        childRoutes: [{
          path: 'curated-links',
          component: CuratedLinks,
        }, {
          path: 'masquerade',
          component: Masquerade,
        }],
      }],
    }, {
      path: '/embed',
      component: NoLayout,
      indexRoute: {
        component: Search,
      },
    }, {
      path: '/examsearch',
      component: NoLayout,
      indexRoute: {
        component: ExamSearch,
      },
    }, {
      // FIXME route to be binned when Sitebuilder searches no longer point at /website
      // Fix also conf/routes
      path: '/website',
      component: Layout,
      indexRoute: {
        component: Search,
      },
    }, {
      // FIXME route to be binned when Sitebuilder searches no longer point at /sitebuilder
      // Fix also conf/routes
      path: '/sitebuilder',
      component: Layout,
      indexRoute: {
        component: Search,
      },
    }];
  }

  render() {
    const { isEmbedded } = this.props;

    return (
      <div>
        {!isEmbedded && <DocumentTitle title="Warwick Search" />}
        <Router history={this.history} routes={this.routeConfig} />
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
  isEmbedded: PropTypes.bool,
};

App.defaultProps = {
  history: browserHistory,
  isEmbedded: false,
};

export default connect()(App);

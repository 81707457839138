import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hash from 'object-hash';
import SuggestedLink from './SuggestedLink';
import { saveClickthrough } from '../actions/search';

export const SuggestedLinks = ({ results, onResultClick }) => {
  let renderedResults;

  if (results.length) {
    renderedResults = results.map(
      (result) => {
        const key = hash(result);

        return (
          <SuggestedLink key={key} result={result} onClick={onResultClick} />
        );
      },
    );

    if (renderedResults) {
      return (
        <div className="suggested-links">
          <h3>Quick links</h3>
          <ul className="results list-unstyled">
            {renderedResults}
          </ul>
        </div>
      );
    }
  }

  return false;
};

SuggestedLinks.propTypes = {
  results: PropTypes.array.isRequired,
  onResultClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  results: state.search.services.suggestedLinks.results,
});

const mapDispatchToProps = dispatch => ({
  onResultClick: (serviceName, title, url, queryId) =>
    dispatch(saveClickthrough('suggestedLinks', title, url, queryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedLinks);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';

export default class MaybeDocumentTitle extends Component {

  shouldComponentUpdate(nextProps) {
    return nextProps.title !== this.props.title;
  }

  render() {
    if (this.context.isEmbedded) {
      return null;
    }

    return <DocumentTitle {...this.props} />;
  }

}

MaybeDocumentTitle.propTypes = DocumentTitle.propTypes;

MaybeDocumentTitle.contextTypes = {
  isEmbedded: PropTypes.bool,
};

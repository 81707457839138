import React, { PropTypes } from 'react';

export const ExamSearchFilterSelect = ({ items, label, value, fieldName, onChange }) => {
  const options = items.map(
    item => (
      <option
        value={item.optionValue}
        key={item.optionValue}
      >
        {item.optionLabel}
      </option>
    ),
  );

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="col-sm-2 control-label text-left">{label}</label>
      <div className="col-sm-6">
        <select
          name={fieldName}
          id={fieldName}
          value={value}
          onChange={onChange}
          className="form-control"
        >
          {options}
        </select>
      </div>
    </div>
  );
};

export default ExamSearchFilterSelect;

ExamSearchFilterSelect.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ExamSearchFilterSelect.defaultProps = {
  items: [],
  label: '',
  fieldName: '',
  value: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from './SearchResult';

export const MapSearchResult = ({ result, ...other }, context) => {
  const { name, permalink, w2gid, building, floor, category, showThumbnail } = result;
  const { isEmbedded } = context;

  const link = permalink || `https://campus.warwick.ac.uk/?search=${encodeURIComponent(name)}&slid=${w2gid}`;
  const thumbnailLink = `${isEmbedded ? 'https://search.warwick.ac.uk' : ''}/api/map-thumbnail/${w2gid}`;

  const arr = [];
  // There is no category property so this is never true. There is a "categories" property though.
  if (category) arr.push(category);
  if (floor) arr.push(floor);
  if (building && building !== 'Campus Overview') arr.push(building);
  const summary = (
    <span>{arr.filter(() => true).join(', ')}</span>
  );

  const thumbnail = showThumbnail ? {
    src: thumbnailLink,
    alt: `Map thumbnail for ${name}`,
  } : {};

  return (
    <SearchResult
      {...other}
      thumbnail={thumbnail}
      link={link}
      title={name}
      summary={summary}
    />
  );
};

export default MapSearchResult;

MapSearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  queryId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

MapSearchResult.contextTypes = {
  isEmbedded: PropTypes.bool,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from '../components/DocumentTitle';
import CuratedLinksList from '../components/admin/CuratedLinksList';
import * as curatedLinks from '../actions/curatedLinks';

export class CuratedLinks extends Component {
  constructor(props, context) {
    super(props, context);
    this.trySubmit = this.trySubmit.bind(this);
    this.tryDelete = this.tryDelete.bind(this);
    this.tryEdit = this.tryEdit.bind(this);
    this.tryCancelEdit = this.tryCancelEdit.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(curatedLinks.fetchCuratedLinks());
  }

  trySubmit(data) {
    if (data.id == null) {
      this.props.dispatch(curatedLinks.createCuratedLink(data));
    } else {
      this.props.dispatch(curatedLinks.updateCuratedLink(data));
    }
  }

  tryDelete(id) {
    this.props.dispatch(curatedLinks.deleteCuratedLink(id));
  }

  tryEdit(id) {
    this.props.dispatch(curatedLinks.editCuratedLink(id));
  }

  tryCancelEdit() {
    this.props.dispatch(curatedLinks.cancelEditCuratedLink());
  }

  render() {
    const documentTitle = 'Curated links - Warwick Search';

    return (
      <div>
        <DocumentTitle title={documentTitle} />

        <h1>Curated links</h1>

        <CuratedLinksList
          onSubmit={this.trySubmit}
          onDelete={this.tryDelete}
          onEdit={this.tryEdit}
          onCancelEdit={this.tryCancelEdit}
        />
      </div>
    );
  }
}

export default connect()(CuratedLinks);

CuratedLinks.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

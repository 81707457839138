import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forOwn } from 'lodash';

// Component that could be used to display all kinds of results info (e.g.: SEARCH-14)
export const ResultsInfo = ({ scope, resubmitWithoutScope }) => {
  let result = false;
  const scopeMessages = [];

  forOwn(scope, (value, thisScope) => {
    switch (thisScope.toLowerCase()) {
      case 'urlprefix': {
        const prefix = value.replace(/^(https?:\/\/)?(www2\.warwick\.ac\.uk)?/, '');

        scopeMessages.push((
          <li key={thisScope}>
            inside <span className="citeUrl">{prefix}</span>
          </li>
        ));
        break;
      }

      case 'daterange': {
        switch (value) {
          case 'anytime': {
            scopeMessages.push((
              <li key={thisScope}>
                from all time
              </li>
            ));
            break;
          }

          default:
        }
        break;
      }

      default:
    }

    if (scopeMessages.length) {
      // This rule is oversensitive to <a> even though it has a role attribute
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      result = (
        <div className="results-info">
          <div className="cancel">
            <a
              className="btn btn-default"
              role="button"
              tabIndex={0}
              onClick={resubmitWithoutScope}
              id="search-all-warwick"
            >Remove filter</a>
          </div>
          <div className="description">
            <span><i className="far fa-fw fa-folder-open" /> Showing results </span>
            <ul className="scopes">{scopeMessages}</ul>.
          </div>

        </div>
      );
      /* eslint-enable jsx-a11y/no-static-element-interactions */
    }
  });

  return result;
};

ResultsInfo.propTypes = {
  scope: PropTypes.object,
  resubmitWithoutScope: PropTypes.func,
};


const mapStateToProps = state => ({
  scope: state.search.scope,
});

export default connect(mapStateToProps)(ResultsInfo);

ResultsInfo.defaultProps = {
  scope: null,
  resubmitWithoutScope: () => null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { has, isEqual, isEmpty, mapValues, pick } from 'lodash';

import {
  SCOPE_ARGUMENTS, ENDPOINT_REGEX,
} from '../AppConstants';

import * as search from '../actions/search';
import { validLengthTerm } from '../utils';
import * as componentFunctionsHack from '../component_functions';

import DocumentTitle from '../components/DocumentTitle';
import SearchForm from '../components/SearchForm';
import BlogSearchResults from '../components/BlogSearchResults';
import ExamSearchResults from '../components/ExamSearchResults';
import GoSearchResults from '../components/GoSearchResults';
import MapSearchResults from '../components/MapSearchResults';
import PeopleSearchResults from '../components/PeopleSearchResults';
import WebSearchResults from '../components/WebSearchResults';
import WRAPSearchResults from '../components/WRAPSearchResults';
import NoSearchResults from '../components/NoSearchResults';
import SuggestedLinks from '../components/SuggestedLinks';
import ResultsInfo from '../components/ResultsInfo';

// eslint-disable-next-line react/prop-types
const SearchWrapper = ({ isEmbedded, children }) => {
  let result = (
    <div>
      {children}
    </div>
  );

  if (!isEmbedded) {
    result = (
      <div className="search-wrapper">
        {children}
      </div>
    );
  }

  return result;
};

export class Search extends Component {
  constructor(props, context) {
    super(props, context);

    this.submitSearch = this.submitSearch.bind(this);
    this.resubmitWithoutScope = this.resubmitWithoutScope.bind(this);
    this.onClear = this.onClear.bind(this);

    // Hack to allow initiating a search from outside of React.
    componentFunctionsHack.setSubmitSearchFunction(this.submitSearch);

    this.resultsComponents = {
      goSearch: GoSearchResults,
      mapSearch: MapSearchResults,
      peopleSearch: PeopleSearchResults,
      webSearch: WebSearchResults,
      wrapSearch: WRAPSearchResults,
      blogSearch: BlogSearchResults,
      examSearch: ExamSearchResults,
    };

    this.searchServicesKeys = Object.keys(this.resultsComponents);

    const endpoint = (has(props.query, 'endpoint') &&
      props.query.endpoint.match(new RegExp(ENDPOINT_REGEX))) ? props.query.endpoint : '';

    if (props.userLoaded) {
      if (isEmpty(endpoint)) {
        props.dispatch(search.runQuery(props.query));
      } else {
        props.dispatch(search.runEndpointQuery(props.query, endpoint));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const newQuery = nextProps.query;
    const oldQuery = this.props.query;

    // run query if finished authenticating
    let doRun = !isEqual(this.props.userLoaded, nextProps.userLoaded);

    // or search term changed
    doRun = doRun || !isEqual(oldQuery.q, newQuery.q);

    // or scope changed
    const oldScope = pick(oldQuery, SCOPE_ARGUMENTS);
    const newScope = pick(newQuery, SCOPE_ARGUMENTS);
    doRun = doRun || !isEqual(oldScope, newScope);

    if (doRun) {
      const endpoint = (has(nextProps.query, 'endpoint') &&
        nextProps.query.endpoint.match(new RegExp(ENDPOINT_REGEX))) ?
        nextProps.query.endpoint : '';

      if (isEmpty(endpoint)) {
        this.props.dispatch(search.runQuery(newQuery));
      } else {
        this.props.dispatch(search.runEndpointQuery(newQuery, endpoint));
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.scoredServices, this.props.scoredServices) ||
      !isEqual(nextProps.query, this.props.query);
  }

  onClear() {
    this.props.router.push({
      ...this.props.location,
      query: {},
    });
  }

  submitSearch(searchTerm) {
    if (searchTerm && validLengthTerm(searchTerm)) {
      const validArguments = SCOPE_ARGUMENTS.concat(['endpoint', 'debug']);
      const validFoundArguments = pick(this.props.query, validArguments);
      const query = { q: searchTerm, ...validFoundArguments };
      const newLocation = { ...this.props.location, query };
      if (!isEqual(newLocation, this.props.location)) {
        this.props.router.push(newLocation);
      }
    }
  }

  resubmitWithoutScope() {
    const searchTerm = this.props.query.q;
    const debug = !!this.props.location.query.debug;
    const query = { q: searchTerm, debug };
    const newLocation = { ...this.props.location, query };
    this.props.router.push(newLocation);
  }

  render() {
    const { query, scoredServices, showSearchForm } = this.props;
    const { q } = query;

    const documentTitle = q ? `${q} - Warwick Search` : 'Warwick Search';


    this.searchServicesKeys.sort((a, b) =>
      (scoredServices[b] - scoredServices[a]));

    const renderedResults = this.searchServicesKeys.map(
      (serviceKey, serviceRank) => {
        const ResultsComponent = this.resultsComponents[serviceKey];
        return (<ResultsComponent
          key={serviceKey}
          serviceName={serviceKey}
          serviceRank={serviceRank}
        />);
      },
    );

    return (
      <div>
        <DocumentTitle title={documentTitle} />
        <SearchWrapper isEmbedded={this.context.isEmbedded}>
          {showSearchForm ?
            <SearchForm
              searchTerm={q || ''}
              onSubmit={this.submitSearch}
              onClear={this.onClear}
            />
            : null}
          <ResultsInfo resubmitWithoutScope={this.resubmitWithoutScope} />
          {renderedResults}
          <NoSearchResults />
          <SuggestedLinks />
        </SearchWrapper>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { location } = ownProps;
  const { query } = location;

  const scoredServices = mapValues(state.search.services, service => (
    (service.results.length && service.results[0].confidenceScore) ?
      service.results[0].confidenceScore : -1
  ));

  return {
    userLoaded: state.sso.user.authoritative,
    scoredServices,
    query,
  };
}

export default connect(mapStateToProps)(withRouter(Search));

Search.defaultProps = {
  showSearchForm: true,
  userLoaded: false,
  query: {},
  scoredServices: {},
};

Search.propTypes = {
  // overrideable by mywarwick:
  showSearchForm: PropTypes.bool,
  // supplied by redux:
  dispatch: PropTypes.func.isRequired,
  // supplied by react-router:
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // supplied by mapStateToProps:
  userLoaded: PropTypes.bool,
  query: PropTypes.object,
  scoredServices: PropTypes.object,
};

Search.contextTypes = {
  isEmbedded: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router';

/**
 * A <HomeLink> is like an <IndexLink> but also loses its linkiness when active
 */
const HomeLink = (props) => {
  const className = props.activeClassName;
  const { id, children } = props;
  const { router, ...other } = props;

  if (router.isActive(props.to, true)) {
    return id ?
      (<span id={id} className={className}>{children}</span>) :
      (<span className={className}>{children}</span>);
  }

  return <Link {...other} onlyActiveOnIndex />;
};

export default withRouter(HomeLink);

HomeLink.propTypes = {
  router: PropTypes.object.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.string.isRequired,
  id: PropTypes.string,
  activeClassName: PropTypes.string,
};

HomeLink.defaultProps = {
  activeClassName: 'active',
  id: null,
};

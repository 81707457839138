import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

/**
 * A <NavLink> is a <Link> wrapped in a <li> for Bootstrap-convenience
 */
const NavLink = (props) => {
  const { router, id, activeLiClassName, liClassName, children, ...otherProps } = props;

  const className = ((liClassName || '') +
    (router.isActive(props.to) ? ` ${activeLiClassName}` : '')).trim();

  let linkText = '';
  let newChildren = [];
  if (Array.isArray(children)) {
    [linkText, ...newChildren] = children;
  } else {
    linkText = children;
  }

  return id ? (
    <li id={id} className={className}>
      <Link {...otherProps}>{linkText}</Link>
      {newChildren}
    </li>
  ) : (
    <li className={className}>
      <Link {...otherProps}>{linkText}</Link>
      {newChildren}
    </li>
  );
};

export default NavLink;

NavLink.propTypes = {
  router: PropTypes.object.isRequired,
  liClassName: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  activeClassName: PropTypes.string.isRequired,
  activeLiClassName: PropTypes.string.isRequired,
  id: PropTypes.string,
};

NavLink.defaultProps = {
  activeLiClassName: 'active',
  activeClassName: 'active',
  liClassName: '',
  children: null,
  id: null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { noResults } from '../selectors';

export class NoSearchResults extends Component {
  render() {
    const { searchTerm, show } = this.props;
    const hasTerm = searchTerm.length > 0;

    return hasTerm && show && (
      <div className="no-results">
        <h3>No matching results</h3>

        <p>Your search - <b className="reflected-query">{searchTerm}</b> - did not match
        any documents.</p>

        <p>Suggestions:</p>

        <ul>
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
        </ul>
      </div>
    );
  }
}

NoSearchResults.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

NoSearchResults.defaultProps = {
  show: false,
};

const mapStateToProps = state => ({
  searchTerm: state.search.searchTerm.trim(),
  show: noResults(state),
});

export default connect(mapStateToProps)(NoSearchResults);

/* global window */

import 'babel-polyfill';
import { routerReducer } from 'react-router-redux';
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import search from './reducers/search';
import sso from './reducers/sso';
import curatedLink, { resetForm as resetCuratedLinkForm } from './reducers/curatedLink';

const reducer = combineReducers({
  search,
  sso,
  curatedLink,
  routing: routerReducer,
  form: formReducer.plugin({
    curatedLinkCreateForm: resetCuratedLinkForm,
    curatedLinkEditForm: resetCuratedLinkForm,
  }),
});

const devToolsMiddleware = window.devToolsExtension ? window.devToolsExtension() : f => f;

const createStoreWithMiddleware = compose(
  applyMiddleware(thunk),
  devToolsMiddleware,
)(createStore);

export default createStoreWithMiddleware(reducer);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchResults from './SearchResults';
import WebSearchResult from './WebSearchResult';

export const WRAPSearchResults = ({ ...props }) => {
  if (props.results.length) {
    return (
      <SearchResults
        {...props}
        ResultComponent={WebSearchResult}
      />
    );
  }

  return false;
};

WRAPSearchResults.propTypes = {
  results: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  results: state.search.services.wrapSearch.results,
});

export default connect(mapStateToProps)(WRAPSearchResults);

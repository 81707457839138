import { createSelector } from 'reselect';
import { forOwn } from 'lodash';
import { FETCH_STATES } from '../AppConstants';

const getSearchServices = state => state.search.services;
const getSearchTerm = state => state.search.searchTerm;

const excludedServices = ['suggestedLinks'];

export const isFetching = createSelector(
  [getSearchServices],
  (services) => {
    let result = false;

    forOwn(services, (service, key) => {
      if (!excludedServices.includes(key)) {
        const { state } = service;
        if (state === FETCH_STATES.FETCHING) {
          result = true;
          return false; // break
        }
      }
      return true; // loop
    });

    return result;
  },
);

export const hasResults = createSelector(
  [getSearchServices],
  (services) => {
    let result = false;

    forOwn(services, (service, key) => {
      if (!excludedServices.includes(key)) {
        const { results } = service;
        if (results.length > 0) {
          result = true;
          return false; // break
        }
      }
      return true; // loop
    });

    return result;
  },
);

export const noResults = createSelector(
  [getSearchServices],
  (services) => {
    let result = true;

    forOwn(services, (service, key) => {
      if (!excludedServices.includes(key)) {
        const { state, results } = service;
        if (state !== FETCH_STATES.IDLE || results.length > 0) {
          result = false;
          return false; // break
        }
      }
      return true; // loop
    });

    return result;
  },
);

export const isVirgin = createSelector(
  [getSearchServices, getSearchTerm],
  (services, term) => {
    if (term.trim().length > 0) {
      return false;
    }

    let result = true;

    forOwn(services, (service, key) => {
      if (!excludedServices.includes(key)) {
        const { state, results } = service;
        if (state !== FETCH_STATES.IDLE || results.length > 0) {
          result = false;
          return false; // break
        }
      }
      return true; // loop
    });

    return result;
  },
);

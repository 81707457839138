import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

export class UtilityBar extends Component {

  componentWillReceiveProps(nextProps) {
    this.attachAccountPopover(nextProps);
  }

  attachAccountPopover(nextProps) {
    const $element = jQuery(this.accountLinkNode);

    if ($element.data('id7.account-popover') === undefined) {
      $element.accountPopover({
        legacyIframeLink: nextProps.links.popover,
        logoutlink: nextProps.links.logout,
      });
    }
  }

  render() {
    const { user, links } = this.props;
    const roles = user.roles || [];

    const adminLink = roles.includes('admin') && (
      <li><Link id="adminLink" to="/admin" activeClassName="active">Admin</Link></li>
    );

    const authLink = user.authenticated ? (
      <li><a
        ref={(node) => { this.accountLinkNode = node; }}
        href=""
        data-toggle="id7:account-popover"
        data-name={user.name}
        data-loginlink={links.login}
        data-logoutlink={links.logout}
      >
        {this.props.user.name}
        <span className="caret" />
      </a></li>
    ) : (
      <li><a href={links.login}>
        Sign in
      </a></li>
    );

    return (
      <ul>
        {adminLink}
        {authLink}
      </ul>
    );
  }

}

/* eslint-disable react/require-default-props */
UtilityBar.propTypes = {
  user: PropTypes.object,
  links: PropTypes.object,
};
/* eslint-enable react/require-default-props */

const mapStateToProps = state => ({
  user: state.sso.user,
  links: state.sso.links,
});

export default connect(mapStateToProps)(UtilityBar);
